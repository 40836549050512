import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import TokenService from "../services/token.service.js";
import UserService from "../services/user.service.js";
import { setCurrentUserAction } from "./auth.js";
import { setMessage } from "./message.js";

export const getAllUsers = createAsyncThunk(
   "church/getAllUsers",
   async ({ page, size }, thunkAPI) => {
      try {
         const data = await UserService.getAllUsers(page, size);
         return { users: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const updateUser = createAsyncThunk(
   "user/updateUser",
   async (user, thunkAPI) => {
      try {
         const data = await UserService.updateUser(user);
         TokenService.setUser(data);
         return thunkAPI.dispatch(setCurrentUserAction({ user: data }));
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const getChurchSettings = createAsyncThunk(
   "church/getChurchSettings",
   async (thunkAPI) => {
      try {
         const data = await UserService.getChurchSettings();
         return { churchSettings: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const setChurchSettingsForNoUser = createAsyncThunk(
   "church/getChurchSettings",
   async ({ data }, thunkAPI) => {
      return { churchSettings: [data] };
   }
);

const initialState = {
   users: null,
   churchSettings: null,
};

const userSlice = createSlice({
   name: "adminData",
   initialState,
   extraReducers: {
      [getAllUsers.fulfilled]: (state, action) => {
         state.users = action.payload.users;
      },
      [getAllUsers.rejected]: (state, action) => {
         state.users = null;
      },
      [getChurchSettings.fulfilled]: (state, action) => {
         state.churchSettings = action.payload.churchSettings;
      },
      [getChurchSettings.rejected]: (state, action) => {
         state.churchSettings = null;
      },
   },
});

const { reducer } = userSlice;
export default reducer;
