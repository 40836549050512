import api from "./api";

const addFavorite = async (favorite) => {
   const response = await api.post(`/favorite/church`, favorite);
   return response;
};

const removeFavorite = async (favoriteId) => {
   const response = await api.delete(`/favorite/church/${favoriteId}`);
   return response;
};

const getFavoriteChurches = async (userId, page, size) => {
   const response = await api.get(
      `/favorite/churches?userId=${userId}&page=${page}&size=${size}`
   );
   return response.data;
};

const addFavoriteStream = async (favorite) => {
   const response = await api.post(`/favorite/stream`, favorite);
   return response;
};
const removeFavoriteStream = async (favoriteId) => {
   const response = await api.delete(`/favorite/stream/${favoriteId}`);
   return response;
};

const getFavoriteStreams = async (userId, page, size) => {
   const response = await api.get(
      `/favorite/streams?userId=${userId}&page=${page}&size=${size}`
   );
   return response.data;
};

const updateFavoriteNote = async (data) => {
   const response = await api.put(`/favorite/church/note`, data);
   return response;
};

const updateStreamNote = async (data) => {
   const response = await api.put(`/favorite/stream/note`, data);
   return response;
};
const addStreamNote = async (data) => {
   const response = await api.post(`/favorite/stream/note`, data);
   return response;
};

const getNotesByUserId = async (userId, page, size) => {
   const response = await api.get(
      `/favorite/stream/notes?userId=${userId}&page=${page}&size=${size}`
   );
   return response.data;
};

const favoriteService = {
   addFavorite,
   removeFavorite,
   getFavoriteChurches,
   addFavoriteStream,
   removeFavoriteStream,
   getFavoriteStreams,
   updateFavoriteNote,
   updateStreamNote,
   addStreamNote,
   getNotesByUserId,
};

export default favoriteService;
