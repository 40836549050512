import axiosInstance from "./api";
import TokenService from "./token.service";
import { logout, refreshTokenAction } from "../slices/auth";
import toast from "react-hot-toast";

const setupInterceptors = (store) => {
   axiosInstance.interceptors.request.use(
      (config) => {
         const token = TokenService.getLocalAccessToken();
         if (token) {
            config.headers["x-access-token"] = token; // for Node.js Express back-end
         }
         return config;
      },
      (error) => {
         return Promise.reject(error);
      }
   );

   const { dispatch } = store;
   axiosInstance.interceptors.response.use(
      (res) => {
         return res;
      },
      async (err) => {
         const originalConfig = err.config;

         if (originalConfig.url !== "/auth/signin" && err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
               originalConfig._retry = true;

               try {
                  const rs = await axiosInstance.post("/auth/refreshtoken", {
                     refreshToken: TokenService.getLocalRefreshToken(),
                  });
                  const { accessToken } = rs.data;
                  //console.log("is going to set new accessToken", accessToken);
                  dispatch(refreshTokenAction(accessToken));
                  TokenService.updateLocalAccessToken(accessToken);

                  return axiosInstance(originalConfig);
               } catch (_error) {
                  if (_error.response.status === 403) {
                     toast.error(
                        "Your session is expired, please sign out and login again!"
                     );
                     //force user to sign in again
                     setTimeout(() => {
                        dispatch(logout());
                        window.location.href = "/signin";
                     }, 1000);
                  }
                  return Promise.reject(_error);
               }
            }
         }

         return Promise.reject(err);
      }
   );

   (function () {
      const originalFetch = fetch;
      fetch = function () {
         return originalFetch.apply(this, arguments).then(function (data) {
            if (data.status === 401) {
               toast.error(
                  "Your session is expired, please sign out and login again!"
               );
               //force user to sign in again
               setTimeout(() => {
                  dispatch(logout());
                  window.location.href = "/signin";
               }, 1000);
            } else {
               return data;
            }
         });
      };
   })();


};

export default setupInterceptors;
