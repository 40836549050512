import React from "react";
import Layout from "../components/Layout";
const LearnMore = () => {
   return (
      <Layout>
         <h1 className="text-2xl font-semibold my-5">Learn More</h1>
      </Layout>
   );
};

export default LearnMore;
