/* This example requires Tailwind CSS v2.0+ */
import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
//import { MenuIcon, XIcon } from "@heroicons/react/outline";
import SCBlack from "../assets/SClogo-black.png";
import SCWhite from "../assets/SClogo-white.png";
import Search from "./Search";
const navigation = [
  { name: "Home", href: "/", current: true },
  { name: "About Us", href: "/about-us", current: false },
  { name: "Contact", href: "/contact", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Nav({ currentUser, showSuperAdminBoard, logOut }) {
  const location = useLocation();
  const [navbarTransform, setNavbarTransform] = useState(false);
  const { isChurchAdminAvailable } = currentUser || {};

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  //&page=${DEFAULT_PAGE}&size=${DEFAULT_SIZE}
  const searchQuery = `/search/churches?value=${searchTerm}`;

  const changeNavbarBackground = () => {
    if (window.scrollY >= 65) {
      setNavbarTransform(true);
    } else {
      setNavbarTransform(false);
    }
  };

  window.addEventListener("scroll", changeNavbarBackground);

  return (
    <Disclosure
      as="nav"
      //className="absolute w-full bg-white top-0 z-50 filter drop-shadow-lg transition"
      className={classNames(
        navbarTransform
          ? "bg-white"
          : location.pathname !== "/"
          ? "bg-white"
          : "",
        "sticky w-full top-0 filter transition z-50"
      )}
    >
      {({ open }) => (
        <>
          <div
            className={`${
              navbarTransform || location.pathname !== "/" ? "hidden" : "block"
            } bg-mobile bg-cover w-full h-16 md:hidden`}
          ></div>{" "}
          <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
            <div className="relative flex justify-between items-center mb-2 h-12 md:h-16">
              <div className="flex items-center justify-center sm:items-stretch sm:justify-start">
                <div className="mt-2 flex-shrink-0 flex items-center">
                  <Link to="/">
                    {navbarTransform ? (
                      <img
                        src={SCBlack}
                        alt="StreamChurch"
                        className="w-16 md:w-28"
                      />
                    ) : location.pathname !== "/" ? (
                      <img
                        src={SCBlack}
                        alt="StreamChurch"
                        className="w-16 md:w-28"
                      />
                    ) : (
                      <img
                        src={SCBlack}
                        alt="StreamChurch"
                        className=" w-20 md:w-28"
                      />
                    )}
                  </Link>
                </div>
              </div>
              {/* <div className="flex-shrink-0 lg:flex items-center rounded-full h-8 w-96 border p-2"> */}
              {navbarTransform ? (
                <div className="border p-1 m-auto rounded-full flex items-center">
                  <Search
                    searchQuery={searchQuery}
                    setSearchTerm={setSearchTerm}
                  />
                  <button
                    type="button"
                    onClick={() => navigate(searchQuery)}
                    className="hidden md:block"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mx-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </button>
                </div>
              ) : location.pathname !== "/" ? (
                <div className="border p-1 m-auto rounded-full flex justify-between items-center bg-white">
                  <Search
                    searchQuery={searchQuery}
                    setSearchTerm={setSearchTerm}
                  />
                  <button
                    type="button"
                    onClick={() => navigate(searchQuery)}
                    className="hidden md:block"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5 mx-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </button>
                </div>
              ) : (
                ""
              )}
              <div className=" inset-y-0  flex items-center  sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                {/* Profile dropdown */}
                {currentUser ? (
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-white py-1 px-2 md:py-2 md:px-4 flex items-center shadow-sm text-gray-700 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                        <span className="sr-only">Open user menu</span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 md:h-6 md:w-6 mr-2"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          strokeWidth={3}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4 md:h-6 md:w-6 "
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="flex flex-col px-2 origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/dashboard/profile"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Your Profile
                            </Link>
                          )}
                        </Menu.Item>
                        {showSuperAdminBoard && (
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/dashboard/user-management"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                User Management
                              </Link>
                            )}
                          </Menu.Item>
                        )}
                        {(showSuperAdminBoard || isChurchAdminAvailable) && (
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                to="/dashboard/church-management"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Church Management
                              </Link>
                            )}
                          </Menu.Item>
                        )}
                        {showSuperAdminBoard && (
                          <>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/dashboard/top-search-management"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Top Search Management
                                </Link>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <Link
                                  to="/dashboard/pastor-management"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Pastors Management
                                </Link>
                              )}
                            </Menu.Item>
                          </>
                        )}
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/dashboard/profile/account"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                            >
                              Account Settings
                            </Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/signin"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700"
                              )}
                              onClick={logOut}
                            >
                              Sign out
                            </Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                ) : (
                  <Link
                    to="/signin"
                    className={classNames(
                      navbarTransform
                        ? "text-gray-500"
                        : location.pathname !== "/"
                        ? "text-gray-500"
                        : "text-gray-500",
                      "flex items-center p-2 bg-transparent font-medium text-sm leading-tight rounded hover:text-gray-700  focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 transition duration-150 ease-in-out"
                    )}
                  >
                    Sign In
                  </Link>
                )}
              </div>
            </div>
          </div>
          <Disclosure.Panel className="sm:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as="a"
                  href={item.href}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 text-white"
                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                    "block px-3 py-2 rounded-md text-base font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
