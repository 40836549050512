import api from "./api";
import EncryptionService from "./encryption.service";
import TokenService from "./token.service";
const register = async (username, email, password) => {
   const encodedPassword = await EncryptionService.encryptMessage(password);
   return api
      .post("/auth/signup", {
         username,
         email,
         encodedPassword,
      })
      .then((response) => {
         if (response.data.accessToken) {
            TokenService.setUser(response.data);
         }

         return response.data;
      });
};

const login = async (loginId, password) => {
   const encodedPassword = await EncryptionService.encryptMessage(password);
   return api
      .post("/auth/signin", {
         loginId,
         encodedPassword,
      })
      .then((response) => {
         if (response.data.accessToken) {
            TokenService.setUser(response.data);
         }

         return response.data;
      });
};

const logout = () => {
   TokenService.removeUser();
};

const getCurrentUser = () => {
   return TokenService.getUser();
};

const getPublicKey = () => {
   return api.get("/auth/publickey");
};

const forgotPassword = async (email) => {
   const response = await api.post(`/auth/forgot-password`, email);
   return response;
};
const checkResetPasswordToken = async ({ hash, token }) => {
   const response = await api.get(
      `/auth/checkResetPasswordToken/${hash}/${token}`
   );
   return response;
};

const updatePassword = async (user) => {
   const encodedPassword = await EncryptionService.encryptMessage(
      user.password
   );
   //remove plain password in object
   user.password = undefined;
   //set encoded password then send through API
   user.encodedPassword = encodedPassword;
   const response = await api.post(`/auth/reset-password`, user);
   return response;
};

const signUpWithEmail = async (email) => {
   const response = await api.post(`/auth/signup-with-email`, email);
   return response;
};

const authService = {
   register,
   login,
   logout,
   getCurrentUser,
   getPublicKey,
   forgotPassword,
   checkResetPasswordToken,
   updatePassword,
   signUpWithEmail,
};

export default authService;
