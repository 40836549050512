import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
const useIsSuperAdmin = () => {
   const [showSuperAdminBoard, setShowSuperAdminBoard] = useState(false);
   const { user: currentUser } = useSelector((state) => state.auth);
   useEffect(() => {
      if (!_.isEmpty(currentUser) && !_.isEmpty(currentUser.roles)) {
         setShowSuperAdminBoard(currentUser.roles.includes("ROLE_SUPERADMIN"));
      } else {
         setShowSuperAdminBoard(false);
      }
   }, [currentUser]);
   return [showSuperAdminBoard];
};

export default useIsSuperAdmin;
