const SmallCard = ({ name }) => {
  return (
    <div className="border-2 border-denom shadow-md w-full hover:bg-darkest hover:border-none hover:text-white">
      <div className="py-5 text-center mx-2">
        <div className="block text-sm font-semibold uppercase">{name}</div>
      </div>
    </div>
  );
};

export default SmallCard;
