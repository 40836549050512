import api from "./api";
import TokenService from "./token.service";

export const userRoles = {
   ROLE_ADMIN: "Administrator",
   ROLE_SUPERADMIN: "Super Administrator",
   ROLE_USER: "User",
};

const updateUser = (user) => {
   return api.put("/auth/user", user).then((response) => {
      if (response.data.accessToken) {
         TokenService.setUser(response.data);
      }

      return response.data;
   });
};

const getAllUsers = async (page = 0, size=100) => {
   const response = await api.get(
      `/auth/admin/users?page=${page}&size=${size}`
   );
   return response.data;
};

const addUser = async (user) => {
   const response = await api.post(`/auth/admin/user`, user);
   return response;
};
const updateUserByAdmin = async (user) => {
   const response = await api.put(`/auth/admin/user`, user);
   return response;
};

const removeUserByAdmin = async (userId) => {
   const response = await api.delete(`/auth/admin/user/${userId}`);
   return response;
};

const checkUserEmail = (email) => {
   return api.post("/auth/user/email", email);
};

const getUserImage = async (key) => {
   const response = await api.get(`/s3/image/${key}`);
   return response.data;
};

const getChurchSettings = async () => {
   const response = await api.get(`/church/settings`);
   return response.data;
};

const updateChurchSetting = async (data) => {
   const response = await api.put(`/auth/admin/church/settings`, data);
   return response;
};

const UserService = {
   updateUser,
   checkUserEmail,
   getAllUsers,
   addUser,
   updateUserByAdmin,
   removeUserByAdmin,
   getUserImage,
   getChurchSettings,
   updateChurchSetting,
};
export default UserService;
