import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Layout from "../components/Layout";
import { Formik, Form, ErrorMessage } from "formik";
import FieldFormik from "../components/Fields/FieldFormik";
import Label from "../components/Fields/Label";
import BaseButton from "../components/Buttons/BaseButton";
import BaseAlert from "../components/Texts/BaseAlert";
import { forgotPasswordSchema } from "../lib/validation/inputSchema";
import authService from "../services/auth.service";
const ResetPassword = () => {
   const { message } = useSelector((state) => state.message);
   const [loading, setLoading] = useState(false);
   const [isDone, setIsDone] = useState(false);
   const [resetMessage, setResetMessage] = useState();
   const [emailForReset, setEmailForReset] = useState();

   useEffect(() => {
      return () => setIsDone(false);
   }, []);

   const onResetPassword = async (values) => {
      const { email } = values;
      setEmailForReset(email);
      setLoading(true);
      try {
         const response = await authService.forgotPassword({
            email: email.toLowerCase(),
         });
         if (response.status === 200) {
            setIsDone(true);
            setLoading(false);
            //setResetMessage(response.data.message)
         }
         setLoading(false);
      } catch (error) {
         setLoading(false);
         setResetMessage(
            error.response?.data?.message ||
               "Failed to send out a password request. If the problem continues, please contact the administrator."
         );
      }
   };
   if (isDone) {
      return (
         <Layout>
            <div className="p-5 max-w-md mx-auto mt-10">
               <h1 className="text-3xl py-5">Reset Password</h1>
               <p>We sent a reset password instructions to:</p>
               <h2 className="font-medium leading-tight text-4xl mt-0 mb-2 text-gray-600 lowercase">
                  {emailForReset}
               </h2>
            </div>
         </Layout>
      );
   }

   return (
      <Layout>
         <div className="p-5 max-w-md mx-auto mt-10">
            <h1 className="text-3xl py-5 text-center">Reset Password</h1>
            <p className="my-2">
               Enter your email address and we'll email you instructions on how
               to reset your password
            </p>
            {message && message.message && (
               <BaseAlert type="danger" text={message.message} />
            )}
            {resetMessage && <BaseAlert type="danger" text={resetMessage} />}
            <Formik
               initialValues={{
                  email: "",
               }}
               //validate in front end
               validationSchema={forgotPasswordSchema}
               onSubmit={(values) => {
                  onResetPassword(values);
               }}
            >
               <Form>
                  <div className="form-group">
                     <Label label="Email" htmlFor="Email" />
                     <FieldFormik id="email" name="email" />
                     <ErrorMessage
                        component="a"
                        className="text-red-500 text-sm"
                        name="email"
                     />
                  </div>
                  <div className="mt-8 flex">
                     <BaseButton
                        type="submit"
                        disabled={loading}
                        loading={loading ? 1 : 0}
                        text="Reset Password"
                     />
                  </div>
               </Form>
            </Formik>
         </div>
      </Layout>
   );
};

export default ResetPassword;
