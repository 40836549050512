import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./slices/auth";
import churchReducer from "./slices/church.slice";
import favoriteReducer from "./slices/favorite.slice";
import messageReducer from "./slices/message";
import userReducer from "./slices/user.slice";

const reducer = {
   auth: authReducer,
   message: messageReducer,
   churchData: churchReducer,
   favoriteData: favoriteReducer,
   adminData: userReducer,
};

const store = configureStore({
   reducer: reducer,
   devTools: true,
});

export default store;
