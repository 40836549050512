import { useNavigate } from "react-router-dom";
const Search = ({ setSearchTerm, searchQuery }) => {
   const navigate = useNavigate();

   const onSubmit = (e) => {
      e.preventDefault();
      return navigate(searchQuery);
   };

   return (
      <div className="w-full ml-2">
         <form onSubmit={onSubmit} className="h-5 flex items-center">
            <input
               className="outline-none pl-1 w-48 md:w-72 text-xs md:text-sm"
               placeholder="Name, Location, Denomination..."
               onChange={(e) => setSearchTerm(e.target.value)}
            ></input>
         </form>
      </div>
   );
};

export default Search;
