import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SearchFilterInput from "./Fields/SearchFilterInput";
import Layout from "./Layout";
export default function Hero() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOpt, setFilterOpt] = useState("");
  //&page=${DEFAULT_PAGE}&size=${DEFAULT_SIZE}
  const makeSearchQuery = () => {
    if (filterOpt === "" || filterOpt === "all") {
      return `/search/churches?value=${searchTerm}`;
    } else {
      return `/search/churches?filter=${filterOpt}&value=${searchTerm}`;
    }
  };
  //const searchQuery = `/search/churches?value=${searchTerm}`;

  const onSubmit = (e) => {
    e.preventDefault();
    return navigate(makeSearchQuery());
  };
  return (
    <div className="flex items-stretch bg-church bg-no-repeat md:bg-[center_bottom_-4rem] bg-cover mb-8 -mt-16">
      <Layout>
        <div className="relative h-hero self-center flex flex-col justify-center">
          <h1 className="block text-center text-lg md:text-4xl font-semibold font-roboto-slab italic mx-10 md:mx-28 text-slate-800 capitalize mb-4">
            Connecting people to church.
          </h1>
          <SearchFilterInput
            setSearchTerm={setSearchTerm}
            onSubmit={onSubmit}
            searchQuery={makeSearchQuery()}
            setFilterOpt={setFilterOpt}
            filterOpt={filterOpt}
          />
        </div>
      </Layout>
    </div>
  );
}
