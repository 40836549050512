import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import favoriteService from "../services/favorite.service.js";
import { setMessage } from "./message.js";

export const getFavoriteChurches = createAsyncThunk(
   "favorite/getFavoriteChurches",
   async ({ userId, page, size }, thunkAPI) => {
      try {
         const data = await favoriteService.getFavoriteChurches(
            userId,
            page,
            size
         );

         return { favorites: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const setEmptyFavoriteChurches = createAsyncThunk(
   "favorite/setEmptyFavoriteChurches",
   async ({}, thunkAPI) => {
      return { favorites: [] };
   }
);

export const getFavoriteStreams = createAsyncThunk(
   "favorite/getFavoriteStreams",
   async ({ userId, page, size }, thunkAPI) => {
      try {
         const data = await favoriteService.getFavoriteStreams(
            userId,
            page,
            size
         );

         return { favorites: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const getFavoriteStreamNotes = createAsyncThunk(
   "favorite/getFavoriteStreamNotes",
   async ({ userId, page, size }, thunkAPI) => {
      try {
         const data = await favoriteService.getNotesByUserId(
            userId,
            page,
            size
         );

         return { favorites: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

const initialState = {
   favorites: null,
   favoriteStreams: null,
   notes: null,
};

const favoriteSlice = createSlice({
   name: "favoriteData",
   initialState,
   extraReducers: {
      [getFavoriteChurches.fulfilled]: (state, action) => {
         state.favorites = action.payload.favorites;
      },
      [getFavoriteChurches.rejected]: (state, action) => {
         state.favorites = null;
      },
      [getFavoriteStreams.fulfilled]: (state, action) => {
         state.favoriteStreams = action.payload.favorites;
      },
      [getFavoriteStreams.rejected]: (state, action) => {
         state.favoriteStreams = null;
      },
      [getFavoriteStreamNotes.fulfilled]: (state, action) => {
         state.notes = action.payload.favorites;
      },
      [getFavoriteStreamNotes.rejected]: (state, action) => {
         state.notes = null;
      },
   },
});

const { reducer } = favoriteSlice;
export default reducer;
