import React from "react";
import Layout from "../components/Layout";
import PlayerImage from "../assets/icons/icon1.png";
import LanguageImage from "../assets/icons/17languages.png";
import ChurchImage from "../assets/icons/10000churches.png";
import CountryImage from "../assets/icons/50states.png";
import CrossImage from "../assets/icons/39denoms.png";
import WorldImage from "../assets/icons/3countries.png";
import { languages } from "../lib/helper";

const AboutUs = () => {
  return (
    <div className="w-full px-8 md:px-20">
      <div className="grid grid-cols-2 mt-4">
        <div className="text-3xl md:text-7xl md:ml-8 font-bold leading-tight font-roboto-slab italic">
          Connecting People to Church.
        </div>
        <div className="bg-about bg-cover bg-right-bottom"></div>
      </div>
      {/* <div className="h-12 md:h-96 w-full bg-black my-8">Hello</div> */}
      <div className="font-serif text-sm md:text-base mt-4">
        <div className="my-8 md:text-3xl text-center mb-4 font-semibold font-roboto-slab">
          What is Stream Church?
        </div>
        <div className="mb-4">
          Stream Church exists to help bridge the gap between people and Church.
          Our desire is to make it easier for people from all demographics to
          explore, visit, search and get connected to Church. Stream Church was
          designed and created for you; for the person who finds church
          intimidating, for the senior citizen who finds it difficult to search
          for church on google or YouTube, for the millions of people who move
          cities each year and have no idea how to start their church search. No
          matter who you are or what your background is Stream Church is there
          to make it easier for you to find Church.
        </div>
        <div className="mb-4">
          For Churches on our platform, our hope is that you find it easier to
          connect with your online audience. One of the biggest hurdles for
          Churches who choose to stream their services online is figuring out
          how to properly minister to those tuning in each week. Through Stream
          Church we make it easier for this to happen and we help those
          searching for Church get connected to a Church.
        </div>
        <div className="my-8 py-4 bg-gradient-to-r from-[#397583] to-[#1B223D] grid md:grid-cols-6 align-middle items-center justify-center text-white w-full font-roboto-slab">
          <div className="flex justify-center grid-rows-2 flex-col items-center my-4">
            <img src={PlayerImage} width={50} />
            <div className="">10,000+</div>
            <div className="text-sm">Churches in Database</div>
          </div>
          <div className="flex justify-center grid-rows-2 flex-col items-center my-4">
            <img src={LanguageImage} width={50} />
            <div>6,500+</div>
            <div className="text-sm">Services Streamed Weekly</div>
          </div>
          <div className="flex justify-center grid-rows-2 flex-col items-center my-4">
            <img src={ChurchImage} width={50} />
            <div>17</div>
            <div className="text-sm">Languages</div>
          </div>
          <div className="flex justify-center grid-rows-2 flex-col items-center my-4">
            <img src={CountryImage} width={50} />
            <div>39</div>
            <div className="text-sm">Denominations</div>
          </div>
          <div className="flex justify-center grid-rows-2 flex-col items-center my-4">
            <img src={CrossImage} width={50} />
            <div>50</div>
            <div className="text-sm">States</div>
          </div>
          <div className="flex justify-center grid-rows-2 flex-col items-center my-4">
            <img src={WorldImage} width={50} />
            <div>3</div>
            <div className="text-sm">Countries</div>
          </div>
        </div>
        <div>
          Our platform is unique because it allows the user to specify and
          filter through a myriad of search data points. Through our enhanced
          search filters, you now have the capability to search through a
          database of around 10,000 Churches by location, senior pastor,
          denomination, doctrinal beliefs or social viewpoints. Stream Church is
          constantly evolving and adding new data points in order to make it
          easier for you to search and get connected to Church. We are committed
          to being a platform that exclusively hosts Church, we are not designed
          to host cultural, social or political content. No matter what page you
          go to on our site you know that you are visiting Church. Thats it, no
          other content is published, no other agenda is pushed. We exist to
          help you know God through hearing the Word at Church.
        </div>
      </div>
      <div className="my-8 flex justify-center">
        <div className="md:w-3/4 text-xl font-bold text-center md:mx-48 font-roboto-slab italic">
          We exist to help you know God through the hearing of His Word.
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
