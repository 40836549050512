import React from "react";
import Layout from "../components/Layout";
import PlayerImage from "../assets/icons/icon1.png";
import LanguageImage from "../assets/icons/17languages.png";
import ChurchImage from "../assets/icons/10000churches.png";
import CountryImage from "../assets/icons/50states.png";
import CrossImage from "../assets/icons/39denoms.png";
import WorldImage from "../assets/icons/3countries.png";
import { languages } from "../lib/helper";

const Founder = () => {
  return (
    <div className="w-full px-8 md:px-20">
      <div className="grid grid-cols-2 mt-4">
        <div className="text-3xl md:text-7xl md:ml-8 font-bold leading-tight font-roboto-slab italic">
          Meet the founder
        </div>
        <div className="bg-about bg-cover bg-right-bottom"></div>
      </div>
      {/* <div className="h-12 md:h-96 w-full bg-black my-8">Hello</div> */}
      <div className="font-serif text-sm md:text-base mt-4 mx-8 md:mx-40">
        <div className="my-12 py-4 text-3xl text-center mb-4 font-semibold font-roboto-slab">
          Hope Tchividjian Saliba
        </div>
        {/* <div className="grid grid-cols-2"> */}
        <div className="mb-4 object-right ">
          <img
            className="md:float-left mx-auto md:mr-2"
            src="https://streamchurch-bucket.s3.us-west-1.amazonaws.com/Screenshot+2023-02-18+at+6.52.08+PM.png"
            width={300}
          />
        </div>
        <div className="mb-4 text-lg text-justify ">
          Hope Tchividjian Saliba is a dynamic Christian leader who is carrying
          forward the legacy of her great-grandfather, the renowned evangelist
          Billy Graham. As the oldest great-granddaughter of Graham, she grew up
          with a deep appreciation for the transformative power of the gospel
          message and has dedicated her life to spreading that message to as
          many people as possible. In pursuit of that goal, Hope founded
          StreamChurch, the largest church streaming directory available on the
          internet. This innovative platform is designed to connect people with
          churches around the world and help them deepen their faith and
          connection to God. The work that Hope is doing through StreamChurch
          represents the next step in a great family legacy of bringing the
          message of Jesus Christ to people around the world. Her
          great-grandfather, Billy Graham, was a pioneer in using radio and
          television to spread the gospel, and Hope is continuing that tradition
          by harnessing the power of the internet to reach even more people with
          the transformative message of God's love. Hope Tchividjian Saliba is a
          visionary leader who is making a significant impact in the world of
          Christian ministry. Through her work with Stream Church, she is
          helping to build a stronger, more connected Christian community and
          bringing hope and healing to people around the globe.
        </div>
        {/* </div> */}
      </div>
      <div className="mt-20 flex justify-center">
        <div className="md:w-3/4 text-xl font-bold text-center md:mx-48 font-roboto-slab italic">
          We exist to help you know God through the hearing of His Word.
        </div>
      </div>
    </div>
  );
};

export default Founder;
