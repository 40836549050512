import React from "react";
import { Field } from "formik";

const FieldFormik = (props) => {
   const block = props.block || "block";
   return (
      <Field
         className={
            "text-gray-700 focus:outline-none focus:shadow-outline border border-gray-300 rounded py-2 px-4 w-full appearance-none " +
            block
         }
         {...props}
      />
   );
};

export default FieldFormik;
