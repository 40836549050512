import React from "react";

const BaseHeading = ({ title }) => {
   return (
      <h2 className="font-medium leading-tight text-4xl mt-0 mb-2 text-gray-600 capitalize">
         {title}
      </h2>
   );
};

export default BaseHeading;
