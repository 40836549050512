import api from "./api";

const sendEmail = async (email, token) => {
   const response = await api.post(`/email/send`, { emailData: email, token });
   return response;
};

const sendPublicForumSubmission = async (type, data, token) => {
   const response = await api.post(`/public/form-submission`, { type, data, token });
   return response;
};

const emailService = {
   sendEmail,
   sendPublicForumSubmission
};

export default emailService;
