import React, { Fragment, useEffect } from "react";
import Hero from "../components/Hero";
import Recommended from "../components/Recommended";
import Denominations from "../components/Denominations";
import MajorCities from "../components/MajorCities";
import ChurchFinderCTA from "../components/ChurchFinderCTA";
import Cta from "../components/Cta";
import Discover from "../components/Discover";
import Favourites from "../components/Favourites";
import ExtraFooter from "../components/ExtraFooter";
import NearByChurches from "../components/NearByChurches";
import { useDispatch, useSelector } from "react-redux";
import { getChurchSettings } from "../slices/user.slice";
import useIsMobile from "../hooks/useIsMobile";
import MobileHeader from "../components/mobile/MobileHeader";
import { useGeolocated } from "react-geolocated";
import ReactGA from "react-ga";
import DonationForm from "../components/DonationForm";

const Home = () => {
  const dispatch = useDispatch();
  const churchSettings = useSelector((state) => state.adminData.churchSettings);
  const isMobile = useIsMobile();
  const { user: currentUser } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getChurchSettings());
    return () => {};
  }, [dispatch]);
  const isRecommended = churchSettings && churchSettings[0]?.value;

  useEffect(() => {
    ReactGA.pageview("/home");
  }, []);

  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  return (
    <Fragment>
      {isMobile ? <MobileHeader /> : <Hero />}
      {/* {isRecommended ? (
        <Recommended
          switchRecLive={
            <SwitchRecLive
              isSwitched={isRecommended}
              title={isRecommended ? "Switch to Favorites" : "Switch to Recommended"}
            />
          }
        />
      ) : (
        <Favourites
          switchRecLive={
            <SwitchRecLive
              isSwitched={isRecommended}
              title={isRecommended ? "Switch to Favorites" : "Switch to Recommended"}
            />
          }
        />
      )} */}
      <DonationForm />
      <Recommended />
      <ChurchFinderCTA />
      {isGeolocationAvailable && isGeolocationEnabled && coords && (
        <NearByChurches
          latitude={coords.latitude}
          longitude={coords.longitude}
        />
      )}
      <Favourites />
      <Denominations />
      <MajorCities />
      <Discover />
    </Fragment>
  );
};

export default Home;
