import Layout from "./Layout";
import Divider from "./Texts/Divider";
import SCBlack from "../assets/SClogo-black.png";
import SCWhite from "../assets/SClogo-white.png";
import MagOne from "../assets/icons/Mag-1.png";
import Texture from "../assets/textures/rightcorner-spraypaint.png";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  useLocation();
  let currentPage = window.location.pathname;

  const cta = (
    <div className="bg-gradient-to-tr from-button to-sc-green w-full md:w-3/5 h-20 md:h-28 -mb-32 md:-mb-36 mt-10 z-20 rounded-full flex flex-col justify-center align-center">
      <div className="flex flex-row justify-center md:justify-between items-center md:p-16 space-x-10">
        <div className="flex flex-col">
          <span className="text-base font-roboto-slab md:text-xl text-white">
            Create an Account
          </span>
          <span className="text-xs md:text-sm text-white">
            Do more than just stream!
          </span>
        </div>
        <div>
          <Link to={"/signup"}>
            <button className="p-2 px-4 md:px-6 border border-white text-sm md:text-base uppercase text-white tracking-wide">
              Sign Up
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col items-center">
      {currentPage == "/" || currentPage == "/about-us" ? cta : ""}
      <div
        className={`bg-footer md:bg-footer-texture bg-cover w-full mt-20 ${
          currentPage == "/" || currentPage == "/about-us" ? "pt-20" : ""
        } z-10`}
      >
        <div className="p-4 sm:p-8 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:flex md:justify-between">
            <div className="grid grid-cols-3 gap-8 sm:gap-6 sm:grid-cols-3">
              <div>
                <h2 className="mb-6 font-semibold capitalize text-white">
                  Learn More
                </h2>
                <ul className="text-gray-400 text-sm leading-none">
                  <li className="mb-4">
                    <Link to="about-us" className="hover:underline">
                      About
                    </Link>
                  </li>
                  <li className="mb-4">
                    <a
                      href="https://blog.streamchurch.net"
                      className="hover:underline"
                    >
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 font-semibold capitalize text-white">
                  Get In Touch
                </h2>
                <ul className="text-gray-400 text-sm leading-none">
                  <li className="mb-4">
                    <Link to="contact" className="hover:underline ">
                      Contact Us
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link to="press-kit" className="hover:underline">
                      Press Kit
                    </Link>
                  </li>
                  <li className="">
                    <Link to="careers" className="hover:underline">
                      Careers
                    </Link>
                  </li>
                </ul>
              </div>
              <div>
                <h2 className="mb-6 font-semibold capitalize text-white">
                  Church Management
                </h2>
                <ul className="text-gray-400 text-sm leading-none">
                  <li className="mb-4">
                    <Link
                      to="/new-church-submission"
                      className="hover:underline"
                    >
                      Add New Church
                    </Link>
                  </li>
                  <li className="mb-4">
                    <Link
                      to="/manage-church-submission"
                      className="hover:underline"
                    >
                      Manage Church
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/edit-church-submission"
                      className="hover:underline"
                    >
                      Edit Church Info
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="hidden md:block">
              <img src={MagOne} width={110} />
            </div>
          </div>
          <div>
            <Divider>
              <img src={SCWhite} alt="StreamChurch" width={110} />
            </Divider>{" "}
          </div>
          <div className="flex items-center justify-center">
            <span className="text-sm text-center text-gray-400">
              © 2023{" "}
              <a href="https://flowbite.com/" className="hover:underline">
                StreamChurch™
              </a>{" "}
              All Rights Reserved |{" "}
              <Link to="privacy" className="hover:underline">
                Privacy Policy
              </Link>{" "}
              |{" "}
              <Link to="tos" className="hover:underline">
                Terms &amp; Conditions
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
