import { useNavigate, useSearchParams } from "react-router-dom";
import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import SearchCard from "../components/SearchCard";
import Layout from "../components/Layout";
import {
  getChurchesBySearchVector,
  getChurchesByAdvancedSearch,
} from "../slices/church.slice";
import Pagination from "../components/Pagination";
import _, { forEach, values } from "lodash";
import BaseHeading from "../components/Texts/BaseHeading";
import BaseAlert from "../components/Texts/BaseAlert";
import Loading from "../components/Animations/Loading";
import { Formik, Form } from "formik";
import FieldFormik from "../components/Fields/FieldFormik";
import Label from "../components/Fields/Label";
import BaseButton from "../components/Buttons/BaseButton";
import churchService from "../services/church.service";
import { Field } from "formik";
import { ucword } from "../../src/lib/helper";
import FEATURE from "../featureConfig";
import RangeSlider from "../components/NumberSliderContainer";
import commonService from "../services/common.service";
import ReactGA from "react-ga";

const styleOfWorshipList = [
  { id: "Liturgical", name: "Liturgical" },
  { id: "Contemporary", name: "Contemporary" },
  { id: "Traditional", name: "Traditional" },
];

const baptismList = [
  { id: "Infant", name: "Infant" },
  { id: "Believers", name: "Believers" },
];

const abortionViewsList = [
  { id: "Pro-Choice", name: "Pro-Choice" },
  { id: "Pro-Life", name: "Pro-Life" },
];

const communionList = [
  { id: "Symbolic", name: "Symbolic" },
  { id: "Spiritual Presence", name: "Spiritual Presence" },
  { id: "Consubstantiation", name: "Consubstantiation" },
  { id: "Transubstantiation", name: "Transubstantiation" },
];

const lang = require("language-list")();

const DEFAULT_LIMIT = 24;

const buildSelectionBox = (list, name, onChange = () => {}) => (
  <Field
    as="select"
    className="form-select appearance-none
      block
      w-full
      px-3
      py-2
      text-base
      font-normal
      text-gray-700
      bg-white bg-clip-padding bg-no-repeat
      border border-solid border-gray-300
      rounded
      transition
      ease-in-out
      m-0
      focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
    aria-label="Default select example"
    name={name}
    placeholder={name}
    onChange={onChange}
  >
    <option value={""}>Select</option>
    {list &&
      list.map((item, idx) => {
        return (
          <option value={item.id} key={idx}>
            {ucword(item.name)}
          </option>
        );
      })}
  </Field>
);

const SearchAll = () => {
  const dispatch = useDispatch();
  const [selectedDeno, setSelectedDeno] = useState("");
  const [fieldFilter, setFieldFilter] = useState("");
  const [advancedSearchSelections, setAdvancedSearchSelections] = useState({});
  const [loading, setLoading] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(searchParams.get("page") || 1);
  const [specificDenomList, setSpecificDenomList] = useState();
  const [generalDenomList, setGeneralDenomList] = useState();
  const [netoworksList, setNetworkList] = useState();
  const [churchLanguages, setChurchLanguages] = useState();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [isAdvanceSearchVisible, setIsAdvanceSearchVisible] = useState(
    screenWidth > 768 ? true : false
  );
  const [isZipcodeRadiusEnabled, setZipcodeRadiusEnabled] = useState(null);
  const [selectedZipRadius, setSelectedRadius] = useState(50);
  const [citySuggestions, setCitySuggestions] = useState([]);
  const [stateSuggestions, setStateSuggestions] = useState([]);
  const [pastorSuggestions, setPastorSuggestions] = useState([]);
  const [optionCounts, setOptionCounts] = useState({});
  const filter = searchParams.get("filter");
  const searchTitle = searchParams.get("value");
  const searchType = searchParams.get("type");
  const searchDiscover = searchParams.get("discover");
  const pageSize = searchParams.get("limit") || DEFAULT_LIMIT;
  const churches = useSelector((state) => state.churchData.searchResults);
  const formikRef = useRef();
  const doNavigation = useNavigate();

  useEffect(() => {
    ReactGA.pageview("/search-results");
  }, []);

  useEffect(() => {
    setLoading(true);
    if (
      searchTitle ||
      searchType?.toLocaleLowerCase() === "discover" ||
      searchType?.toLocaleLowerCase() === "near-by" ||
      searchType?.toLocaleLowerCase() === "denom" ||
      searchType?.toLocaleLowerCase() === "city" ||
      (!searchTitle && !searchType)
    ) {
      dispatch(
        getChurchesByAdvancedSearch({
          advanceFilter: {},
          page: currentPage - 1,
          size: pageSize,
          value: searchTitle,
          filter: filter,
          type: searchType?.toLocaleLowerCase() || "default",
        })
      )
        .then(() => {
          setLoading(false);
          setAdvancedSearchSelections({});
          setCurrentPage(1);
          if (screenWidth <= 768) setIsAdvanceSearchVisible(false); // Mobile only
          focusToTopSearchElements();
        })
        .catch(() => {
          setLoading(false);
          if (screenWidth <= 768) setIsAdvanceSearchVisible(false); // Mobile only
        });
    }
  }, [dispatch, searchTitle, searchType]);

  useEffect(() => {
    fetchingDenomList();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const advanceSearchCityOnChange = async (e) => {
    formikRef?.current?.handleChange(e);
    setZipcodeRadiusEnabled(!!e.target.value);
    if (e.target.value?.length > 2) {
      const data = await commonService.getCitySuggestions(e.target.value);
      if (data && data.rows) {
        setCitySuggestions(data.rows);
      }
    } else {
      setCitySuggestions([]);
    }
  };

  const advanceSearchStateOnChange = async (e) => {
    formikRef?.current?.handleChange(e);
    if (e.target.value?.length > 1) {
      const data = await commonService.getStateSuggestions(e.target.value);
      if (data && data.rows) {
        setStateSuggestions(data.rows);
      }
    } else {
      setStateSuggestions([]);
    }
  };

  const advanceSearchPastorOnChange = async (e) => {
    formikRef?.current?.handleChange(e);
    if (e.target.value?.length > 1) {
      const data = await commonService.getPastorSuggestionsV2(e.target.value);
      if (data && data.rows) {
        setPastorSuggestions(data.rows);
      }
    } else {
      setPastorSuggestions([]);
    }
  };

  const submitFormIkOnchange = (e) => {
    if (e) {
      formikRef?.current?.handleChange(e);
    }
    setCurrentPage(1);
    formikRef?.current?.submitForm();
  };

  const selectGeneralDenomComp = buildSelectionBox(
    generalDenomList,
    "generalDenom",
    submitFormIkOnchange
  );
  const selectSpecificDenomComp = buildSelectionBox(
    specificDenomList,
    "specdenom",
    submitFormIkOnchange
  );
  const selectChurchLanguage = buildSelectionBox(
    churchLanguages,
    "churchLanguage",
    submitFormIkOnchange
  );
  const selectNetworkListComp = buildSelectionBox(
    netoworksList,
    "network",
    submitFormIkOnchange
  );

  const arrayObjectSort = (array, sortField) => {
    if (!array) return array;

    return array.sort((prev, next) =>
      prev[sortField].localeCompare(next[sortField])
    );
  };

  const fetchingDenomList = async () => {
    const specificDenom = await churchService.getSpecificDenominations();
    setSpecificDenomList(arrayObjectSort(specificDenom, "name"));
    const generalDenom = await churchService.getGeneralDenominations();
    setGeneralDenomList(arrayObjectSort(generalDenom, "name"));
    const languages = await churchService.getAllLanguages();
    setChurchLanguages(arrayObjectSort(languages, "name"));
    const networksResponse = await churchService.getNetworks();
    setNetworkList(arrayObjectSort(networksResponse, "name"));
  };

  const handleWindowSizeChange = () => {
    setScreenWidth(window.innerWidth);
  };

  const doFieldFilter = (data) => {
    if (_.isEmpty(data)) {
      return null;
    }
    if (fieldFilter !== "" && fieldFilter.length >= 2) {
      const kw = fieldFilter.toLowerCase().trim();
      let searchLanguage = kw;
      //search for language
      const languageName = lang.getLanguageName(kw);
      if (languageName) {
        searchLanguage = languageName;
      }

      return data.filter(
        (church) =>
          church.city?.toLowerCase().includes(kw) ||
          church.name?.toLowerCase().includes(kw) ||
          church.address?.toLowerCase().includes(kw) ||
          church.state?.abbreviation?.toLowerCase().includes(kw) ||
          church.pastors[0]?.name?.toLowerCase().includes(kw) ||
          church.seniorPastors[0]?.name?.toLowerCase().includes(kw) ||
          church.zip?.toString().toLowerCase().includes(kw) ||
          //church.language?.toLowerCase().includes(kw) ||
          church.language?.toLowerCase().includes(searchLanguage)
      );
    }
    return data;
  };

  const doDenoFilter = (data) => {
    if (_.isEmpty(data)) {
      return null;
    }
    if (selectedDeno !== "") {
      return doFieldFilter(
        data.filter((church) => {
          return (
            selectedDeno.toLowerCase() ===
            church.denomination?.name?.toLowerCase()
          );
        })
      );
    }
    return doFieldFilter(data);
  };

  const currentChurchesData = useMemo(() => {
    if (!churches || _.isEmpty(churches.rows)) {
      return null;
    }
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return (
      doDenoFilter(churches.rows) &&
      doDenoFilter(churches.rows).slice(firstPageIndex, lastPageIndex)
    );
  }, [churches, currentPage, selectedDeno, fieldFilter, pageSize]);

  const onAdvanceSearchSubmit = async (values) => {
    setLoading(true);
    if (searchTitle) {
      doNavigation("./");
    }

    setCitySuggestions([]);
    setStateSuggestions([]);
    setPastorSuggestions([]);

    focusToTopSearchElements();
    dispatch(
      getChurchesByAdvancedSearch({
        advanceFilter: values,
        page: currentPage - 1,
        size: pageSize,
        value: "", // removed keyword when doing advance search
        filter: filter,
      })
    )
      .then(() => {
        setLoading(false);
        setAdvancedSearchSelections({ ...advancedSearchSelections, ...values });
        setCurrentPage(1);
        if (screenWidth <= 768) setIsAdvanceSearchVisible(false); // Mobile only
      })
      .catch(() => {
        setLoading(false);
        if (screenWidth <= 768) setIsAdvanceSearchVisible(false); // Mobile only
      });
  };

  const focusToTopSearchElements = async () => {
    const delay = (ms) => new Promise((res) => setTimeout(res, ms));
    await delay(100);
    const searchKeyElements = document.querySelector(
      ".advanced-search-selections"
    );
    if (searchKeyElements) {
      searchKeyElements.scrollIntoView({ behavior: "smooth", block: "center" });
      searchKeyElements.previousElementSibling?.focus({ preventScroll: true });
    }
  };

  const mapping = () => {
    while (loading || !churches) {
      return <Loading size={6} />;
    }
    return (
      <>
        {churches?.random && (
          <div className="discover-random-showing">
            Randomly showing few churches here,{" "}
            <a
              className="click-here"
              onClick={() => window.location.reload(false)}
            >
              click here
            </a>{" "}
            to suffle...
          </div>
        )}
        <div className="sm:grid grid-cols-3">
          {churches &&
            currentChurchesData &&
            currentChurchesData.map((church) => (
              <SearchCard
                key={church.id}
                id={church.id}
                name={church.name}
                image={church.image}
                city={church.city}
                state={church.state && church.abbreviation}
                pastor={church.pastorName}
                zipcode={church.zip}
                miles={isZipcodeRadiusEnabled && church?.distance?.miles}
              />
            ))}
        </div>

        {currentChurchesData && (
          <div className="flex justify-end my-5">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={doDenoFilter(churches.rows).length}
              pageSize={pageSize}
              onPageChange={(page) => {
                setCurrentPage(page);
                focusToTopSearchElements();
              }}
            />
          </div>
        )}
      </>
    );
  };

  const renderFormIkCheckbox = ({ name, value, label }) => {
    return (
      <label className="checkbox-label">
        <Field
          type="checkbox"
          name={name}
          value={value}
          onChange={submitFormIkOnchange}
        />
        {label}
      </label>
    );
  };

  if (searchDiscover === "true" && churches && churches.count === 1) {
    doNavigation(`/church/${churches.rows[0].id}`, { replace: true });
  }

  const renderAdvancedSearchSelections = () => {
    const {
      churchLanguage,
      generalDenom,
      specdenom,
      styleOfWorship,
      ordinationOfWomen,
      womanPastors,
      familyStructure,
      baptism,
      abortionViewpoint,
      communion,
      zipcode,
      city,
      state,
      pastor,
      network,
    } = advancedSearchSelections;
    const renderItem = (title, items, fieldName, resetVal) => {
      return (
        <div
          className="selection-item"
          onClick={() => {
            formikRef.current?.setFieldValue(fieldName, resetVal);
            formikRef.current?.submitForm();
            setCurrentPage(1);
            if (fieldName === "zipcode") {
              setZipcodeRadiusEnabled(false);
            }
          }}
        >
          {title}: {items} <span className="close">✕</span>
        </div>
      );
    };
    const churchLangFiltered = churchLanguage
      ? churchLanguages.filter((item) => item.id == churchLanguage)
      : [];
    const genearalDenomFiltered = generalDenom
      ? generalDenomList.filter((item) => item.id == generalDenom)
      : [];
    const specificDenomFiltered = specdenom
      ? specificDenomList.filter((item) => item.id == specdenom)
      : [];
    const networksFiltered = network
      ? netoworksList.filter((item) => item.id == network)
      : [];

    let searchResultsLabel =
      "Search results for : " + (searchTitle ? searchTitle.toLowerCase() : "");
    if (searchType == "near-by") {
      searchResultsLabel = "Your nearby churches";
    }
    return (
      <div className="advanced-search-selections">
        {searchTitle && (
          <div
            className="selection-item"
            onClick={() => {
              doNavigation("./");
              onAdvanceSearchSubmit({
                ...advancedSearchSelections,
                zipcodeRadius: selectedZipRadius,
              });
            }}
          >
            {searchResultsLabel} <span className="close">✕</span>
          </div>
        )}
        {city && renderItem("City", city, "city", "")}
        {state && renderItem("State", state, "state", "")}
        {zipcode && renderItem("Zipcode", zipcode, "zipcode", "")}
        {pastor && renderItem("Pastor", pastor, "pastor", "")}
        {churchLangFiltered.length > 0 &&
          renderItem(
            "Language",
            churchLangFiltered[0].name,
            "churchLanguage",
            ""
          )}
        {genearalDenomFiltered.length > 0 &&
          renderItem(
            "Denomination",
            genearalDenomFiltered[0].name,
            "generalDenom",
            ""
          )}
        {networksFiltered.length > 0 &&
          renderItem("Networks", networksFiltered[0].name, "network", "")}
        {specificDenomFiltered.length > 0 &&
          renderItem(
            "Denomination",
            specificDenomFiltered[0].name,
            "specdenom",
            ""
          )}
        {styleOfWorship?.length > 0 &&
          renderItem(
            "Style of Worship",
            styleOfWorship.join(", "),
            "styleOfWorship",
            null
          )}
        {ordinationOfWomen?.length > 0 &&
          renderItem(
            "Ordination of Women",
            ordinationOfWomen.join(", "),
            "ordinationOfWomen",
            null
          )}
        {womanPastors?.length > 0 &&
          renderItem(
            "Woman Pastors",
            womanPastors.join(", "),
            "womanPastors",
            null
          )}
        {familyStructure?.length > 0 &&
          renderItem(
            "Family Structure",
            familyStructure.join(", "),
            "familyStructure",
            null
          )}
        {baptism?.length > 0 &&
          renderItem("Baptism", baptism.join(", "), "baptism", null)}
        {abortionViewpoint?.length > 0 &&
          renderItem(
            "Abortion Viewpoint",
            abortionViewpoint.join(", "),
            "abortionViewpoint",
            null
          )}
        {communion?.length > 0 &&
          renderItem("Communion", communion.join(", "), "communion", null)}
      </div>
    );
  };

  const sliderValueChanged = useCallback((val) => {
    setSelectedRadius(val);
    onAdvanceSearchSubmit({ ...advancedSearchSelections, zipcodeRadius: val });
  });

  const setAdvanceSearchFieldValue = (fieldName, value) => {
    formikRef.current?.setFieldValue(fieldName, value);
    setCitySuggestions([]);
    setStateSuggestions([]);
    setPastorSuggestions([]);
    setCurrentPage(1);
    formikRef.current?.submitForm();
  };

  const toCamelCase = (text) =>
    text
      .toLowerCase()
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();

  const renderCitySuggestions = () => {
    if (citySuggestions.length === 0) {
      return null;
    }

    return (
      <div className="advance-search-suggestions">
        <ul>
          {citySuggestions.map((item) => {
            const cityCamel = toCamelCase(item.city);
            return (
              <li onClick={() => setAdvanceSearchFieldValue("city", cityCamel)}>
                {cityCamel}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderStateSuggestions = () => {
    if (stateSuggestions.length === 0) {
      return null;
    }
    return (
      <div className="advance-search-suggestions">
        <ul>
          {stateSuggestions.map((item) => {
            const stateCamel = toCamelCase(item.name);
            return (
              <li
                onClick={() => setAdvanceSearchFieldValue("state", stateCamel)}
              >
                {stateCamel}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const renderPastorSuggestions = () => {
    if (pastorSuggestions.length === 0) {
      return null;
    }
    return (
      <div className="advance-search-suggestions">
        <ul>
          {pastorSuggestions.map((item) => {
            const pastorCamel = toCamelCase(item.name);
            return (
              <li
                onClick={() =>
                  setAdvanceSearchFieldValue("pastor", pastorCamel)
                }
              >
                {pastorCamel}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <Layout>
      <div className="mx-auto mt-8 max-w-7xl px-0 md:px-4">
        {FEATURE.ADVANCE_SEARCH_FILTER && !isAdvanceSearchVisible && (
          <div className="search-filter-advanced-button">
            <button
              className="border border-gray-300 p-1 px-4 text-sm rounded-md md:ml-2"
              onClick={() => setIsAdvanceSearchVisible(!isAdvanceSearchVisible)}
            >
              Advance Filters
            </button>
          </div>
        )}

        <div className="search-results-container flex">
          {FEATURE.ADVANCE_SEARCH_FILTER && (
            <div
              className={`search-filters ${
                isAdvanceSearchVisible ? "sf-available" : "sf-hidden"
              }`}
            >
              <div className="close-btn">
                <button
                  className="border border-gray-300 p-1 px-4 text-sm rounded-md reset-advance-search"
                  onClick={() => {
                    setAdvancedSearchSelections({});
                    formikRef.current?.setFieldValue("city", "");
                    formikRef.current?.setFieldValue("state", "");
                    formikRef.current?.setFieldValue("zipcode", "");
                    formikRef.current?.setFieldValue("city", "");
                    formikRef.current?.setFieldValue("pastor", "");
                    formikRef.current?.setFieldValue("churchLanguage", "");
                    formikRef.current?.setFieldValue("generalDenom", "");
                    formikRef.current?.setFieldValue("network", "");
                    formikRef.current?.setFieldValue("specdenom", "");
                    formikRef.current?.setFieldValue("styleOfWorship", null);
                    formikRef.current?.setFieldValue("ordinationOfWomen", null);
                    formikRef.current?.setFieldValue("womanPastors", null);
                    formikRef.current?.setFieldValue("familyStructure", null);
                    formikRef.current?.setFieldValue("baptism", null);
                    formikRef.current?.setFieldValue("abortionViewpoint", null);
                    formikRef.current?.setFieldValue("communion", null);
                    formikRef.current?.submitForm();
                    setZipcodeRadiusEnabled(false);
                    setCurrentPage(1);
                  }}
                >
                  Reset Search
                </button>
                <button
                  className="border border-gray-300 p-1 px-4 text-sm rounded-md"
                  onClick={() =>
                    setIsAdvanceSearchVisible(!isAdvanceSearchVisible)
                  }
                >
                  Hide
                </button>
              </div>

              <Formik
                innerRef={formikRef}
                initialValues={{
                  city: "",
                  state: "",
                  zipcode: "",
                  generalDenom: "",
                  specdenom: "",
                  styleOfWorship: "",
                  ordinationOfWomen: "",
                  womanPastors: "",
                  familyStructure: "",
                  baptism: "",
                  abortionViewpoint: "",
                  communion: "",
                  churchLanguge: "",
                }}
                onSubmit={(values) => {
                  onAdvanceSearchSubmit({
                    ...values,
                    zipcodeRadius: selectedZipRadius,
                  });
                }}
              >
                <Form>
                  <div className="form-group">
                    <Label label="Location" />
                    <FieldFormik
                      id="city"
                      name="city"
                      placeholder="City"
                      onChange={(e) => advanceSearchCityOnChange(e)}
                    />
                    {renderCitySuggestions()}
                    <FieldFormik
                      id="state"
                      name="state"
                      placeholder="State"
                      onChange={(e) => advanceSearchStateOnChange(e)}
                    />
                    {renderStateSuggestions()}
                    <FieldFormik
                      onChange={(e) => {
                        setZipcodeRadiusEnabled(!!e.target.value);
                        formikRef?.current?.handleChange(e);
                      }}
                      id="zipcode"
                      name="zipcode"
                      placeholder="Zip code"
                    />
                    <div>
                      <RangeSlider
                        min={0}
                        max={100}
                        value={selectedZipRadius}
                        step={10}
                        label="Select Radius"
                        onChange={(e) => sliderValueChanged(e)}
                        isDisabled={!isZipcodeRadiusEnabled}
                        classes="additional-css-classes"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <Label label="Denomination" />
                    {selectGeneralDenomComp}
                    {selectSpecificDenomComp}
                  </div>
                  <div className="form-group">
                    <Label label="Networks" />
                    {selectNetworkListComp}
                  </div>
                  <div className="form-group">
                    <Label label="Pastor" />
                    <FieldFormik
                      id="pastor"
                      name="pastor"
                      placeholder="Pastor name"
                      onChange={(e) => advanceSearchPastorOnChange(e)}
                    />
                    {renderPastorSuggestions()}
                  </div>
                  <div className="form-group">
                    <Label label="Language" />
                    {selectChurchLanguage}
                  </div>
                  <div className="form-group">
                    <Label label="Style of Worship" />
                    <div role="group" aria-labelledby="checkbox-group">
                      {styleOfWorshipList.map((item) =>
                        renderFormIkCheckbox({
                          name: "styleOfWorship",
                          label: item.name,
                          value: item.id,
                        })
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <Label label="Baptism" />
                    <div role="group" aria-labelledby="checkbox-group">
                      {baptismList.map((item) =>
                        renderFormIkCheckbox({
                          name: "baptism",
                          label: item.name,
                          value: item.id,
                        })
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <Label label="Communion" />
                    <div role="group" aria-labelledby="checkbox-group">
                      {communionList.map((item) =>
                        renderFormIkCheckbox({
                          name: "communion",
                          label: item.name,
                          value: item.id,
                        })
                      )}
                    </div>
                  </div>
                  <div className="form-group">
                    <Label label="Ordination of Women" />
                    <div role="group" aria-labelledby="checkbox-group">
                      {renderFormIkCheckbox({
                        name: "ordinationOfWomen",
                        label: "Yes",
                        value: "Yes",
                      })}
                    </div>
                  </div>
                  <div className="form-group">
                    <Label label="Woman Pastors on Staff" />
                    <div role="group" aria-labelledby="checkbox-group">
                      {renderFormIkCheckbox({
                        name: "womanPastors",
                        label: "Yes",
                        value: "Yes",
                      })}
                    </div>
                  </div>
                  <div className="form-group">
                    <Label label="Family Structure" />
                    <div role="group" aria-labelledby="checkbox-group">
                      {renderFormIkCheckbox({
                        name: "familyStructure",
                        label: "Traditional",
                        value: "Traditional",
                      })}
                      {renderFormIkCheckbox({
                        name: "familyStructure",
                        label: "Progressive",
                        value: "Progressive",
                      })}
                    </div>
                  </div>

                  {FEATURE.ADVANCE_SEARCH_ABORTION_SECTION && (
                    <div className="form-group">
                      <Label label="Abortion Viewpoint" />
                      <div role="group" aria-labelledby="checkbox-group">
                        {abortionViewsList.map((item) =>
                          renderFormIkCheckbox({
                            name: "abortionViewpoint",
                            label: item.name,
                            value: item.id,
                          })
                        )}
                      </div>
                    </div>
                  )}
                  <div className="mt-8 flex advance-search-button">
                    <BaseButton type="submit" text="Update Search" />
                  </div>
                </Form>
              </Formik>
            </div>
          )}
          <div
            className="search-results"
            style={{
              borderLeftWidth: isAdvanceSearchVisible ? "1px" : "0px",
              width: isAdvanceSearchVisible ? "75%" : "100%",
            }}
          >
            {renderAdvancedSearchSelections()}
            {mapping()}
            {!loading &&
              (_.isEmpty(churches) ||
                (_.isEmpty(churches.rows) && (
                  <div className="mt-10 p-2">
                    <BaseAlert
                      text={
                        <div>
                          <BaseHeading title="Not Found!" />
                          <span>
                            Sorry, but nothing matched your search terms. Please
                            try again with some different keywords.
                          </span>
                        </div>
                      }
                    />
                  </div>
                )))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default SearchAll;
