import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import FieldFormik from "../components/Fields/FieldFormik";
import BaseButton from "../components/Buttons/BaseButton";
import Label from "../components/Fields/Label";
import Layout from "../components/Layout";
import emailService from "../services/email.service";
import { newChurchSubmissionFormSchema } from "../lib/validation/inputSchema";
import ReactRecaptcha3 from 'react-google-recaptcha3';

const SITE_RECAPTCHA3_SITEKEY = "6LfDwigiAAAAAJbtpSRjMWz9VxyPJ-0XH9ChYb2m";

const NewChurchSubmission = () => {

   const [loading, setLoading] = useState(false);
   const initValues = {
      name: "",
      address: "",
      zipcode: "",
      website: "",
      youtube: "",
      leadPastor: "",
      other: "",
      userEmail: "",
   };

   const focusToTopSearchElements = async () => {
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(100);
      const searchKeyElements = document.querySelector('.form-submission-title');
      if (searchKeyElements) {
        searchKeyElements.scrollIntoView({ behavior: 'smooth', block: 'center' });
        searchKeyElements.previousElementSibling?.focus({ preventScroll: true });
      }
   }

   useEffect(() => {
      ReactRecaptcha3.init(SITE_RECAPTCHA3_SITEKEY).then(status => {
         console.log("Recaptcha initialized: " + status);
       })
      focusToTopSearchElements();
    }, [])

   const onSend = async (values) => {
      setLoading(true);
      try {

         const token = await ReactRecaptcha3.getToken();
          
         const response = await emailService.sendPublicForumSubmission("NEW_CHURCH_SUBMISSION", values, token);
         if (response.status === 200) {
            toast.success(response.data.message);
            setLoading(false);
         }
         setLoading(false);
      } catch (error) {
         console.log(error)
         toast.error(error.response.data.message);
         setLoading(false);
      }
   };

   return (
      <Layout>
         <div className="global-form-layout">
            <h1 className="text-3xl py-5 text-center form-submission-title">Add your Church to this site!</h1>
            <h4 className="py-5 text-center"> Please submit your church details here. Our team will review the contents, and attach your church to this site or reach out to you for any queries.</h4>
            <hr /> 
            <Formik
               initialValues={initValues}
               validationSchema={newChurchSubmissionFormSchema}
               onSubmit={(values) => {
                  onSend(values);
               }}
            >
               {({ resetForm }) => (
                  <Form>
                     <div className="form-group">
                        <Label label="Church Name" />
                        <FieldFormik id="name" name="name" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="name"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Address" />
                        <FieldFormik id="address" name="address" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="address"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Zipcode" />
                        <FieldFormik id="zipcode" name="zipcode" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="zipcode"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Website" htmlFor="website" />
                        <FieldFormik id="website" name="website" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="website"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Youtube Channel" htmlFor="youtube" />
                        <FieldFormik id="youtube" name="youtube" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="youtube"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Lead Pastor name" htmlFor="leadPastor" />
                        <FieldFormik id="leadPastor" name="leadPastor" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="leadPastor"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Other details" />
                        <FieldFormik
                           as="textarea"
                           rows="5"
                           id="other"
                           name="other"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Your email(We might contact you for further details)" />
                        <FieldFormik id="userEmail" name="userEmail" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="userEmail"
                        />
                     </div>

                     <div className="mt-8 flex flex-wrap gap-x-3">
                        <BaseButton
                           type="submit"
                           disabled={loading}
                           loading={loading ? 1 : 0}
                           text="Submit Church Information"
                        />
                     </div>
                  </Form>
               )}
            </Formik>
         </div>
      </Layout>
   );
};

export default NewChurchSubmission;
