import { useNavigate } from "react-router-dom";

const SearchFilterInput = ({ setSearchTerm, onSubmit, searchQuery }) => {
  const navigate = useNavigate();

  return (
    <div className="border-2 rounded-xl md:rounded-2xl flex p-1 px-4 mt-2 bg-white text-gray-500 text-xl md:w-1/2 place-self-center">
      <button
        type="button"
        className="mr-2 md:mr-5"
        onClick={() => navigate(searchQuery)}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className=" h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
      </button>
      <form className="w-full" onSubmit={onSubmit}>
        <input
          className="outline-none w-full h-8 md:h-10 text-lg text-sm md:text-base"
          placeholder="Search by name, location, denomination..."
          onChange={(e) => setSearchTerm(e.target.value)}
        ></input>
      </form>
    </div>
  );
};

export default SearchFilterInput;
