import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import churchService from "../services/church.service.js";
import { setMessage } from "./message.js";

export const getChurchBySize = createAsyncThunk(
   "church/getChurchBySize",
   async ({ page, size, filter, isManagementRequest = false }, thunkAPI) => {
      try {
         const data = await churchService.getChurchesBySize(page, size, filter, isManagementRequest);
         return { churches: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);
export const getRecommendedChurchesBySize = createAsyncThunk(
   "church/getRecommendedChurchesBySize",
   async ({ page, size }, thunkAPI) => {
      try {
         const data = await churchService.getRecommendedChurchesBySize(
            page,
            size
         );
         return { churches: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const getNearByChurches = createAsyncThunk(
   "church/getNearByChurches",
   async ({ lat, lang }, thunkAPI) => {
      try {
         const data = await churchService.getNearByChurches(
            lat,
            lang
         );
         return { churches: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const getChurchById = createAsyncThunk(
   "church/getChurchById",
   async ({ churchId }, thunkAPI) => {
      try {
         const data = await churchService.getChurchById(churchId);
         return { church: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const getChurchByIdForEdit = createAsyncThunk(
   "church/getChurchByIdForEdit",
   async ({ churchId }, thunkAPI) => {
      try {
         const data = await churchService.getChurchByIdForEdit(churchId);
         return { church: data };
      } catch (error) {
         if (error.response.status == 403) { // Unauthorized request for edit church
            window.location = "/"
         }
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const getChurchWFavoriteById = createAsyncThunk(
   "church/getChurchWFavoriteById",
   async ({ churchId, userId, currentVideoId }, thunkAPI) => {
      try {
         const data = await churchService.getChurchWFavoriteById(
            churchId,
            userId,
            currentVideoId
         );
         return { church: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);
export const getChurchesBySearch = createAsyncThunk(
   "church/getChurchesBySearch",
   async ({ key, value, page, size }, thunkAPI) => {
      try {
         const data = await churchService.getChurchesBySearch(
            key,
            value,
            page,
            size
         );
         return { churches: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const getChurchesBySearchVector = createAsyncThunk(
   "church/getChurchesBySearchVector",
   async ({ page, size, value, filter }, thunkAPI) => {
      try {
         const data = await churchService.getChurchesBySearchVector(
            page,
            size,
            value,
            filter
         );
         return { churches: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const getChurchesByAdvancedSearch = createAsyncThunk(
   "church/getChurchesBySearchVector",
   async ({ page, size, value, filter, advanceFilter, type }, thunkAPI) => {
      try {
         const data = await churchService.fetchAdvanceSearch(
            page,
            size,
            value,
            filter,
            advanceFilter,
            type
         );
         return { churches: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const updateChurchByAdmin = createAsyncThunk(
   "user/updateChurchByAdmin",
   async (church, thunkAPI) => {
      try {
         const response = await churchService.updateChurchByAdmin(church);
         thunkAPI.dispatch(setMessage(response.message));
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

const initialState = {
   churches: null,
   church: null,
   recommendedChurches: null,
   searchResults: null,
};

const churchSlice = createSlice({
   name: "churchData",
   initialState,
   extraReducers: {
      [getChurchBySize.fulfilled]: (state, action) => {
         state.churches = action.payload.churches;
      },
      [getChurchBySize.rejected]: (state, action) => {
         state.churches = null;
      },
      [getRecommendedChurchesBySize.fulfilled]: (state, action) => {
         state.recommendedChurches = action.payload.churches;
      },
      [getRecommendedChurchesBySize.rejected]: (state, action) => {
         state.recommendedChurches = null;
      },
      [getNearByChurches.fulfilled]: (state, action) => {
         state.nearByChurches = action.payload.churches;
      },
      [getNearByChurches.rejected]: (state, action) => {
         state.nearByChurches = null;
      },
      [getChurchById.fulfilled]: (state, action) => {
         state.church = action.payload.church;
      },
      [getChurchById.rejected]: (state, action) => {
         state.church = null;
      },
      [getChurchByIdForEdit.fulfilled]: (state, action) => {
         state.churchForEdit = action.payload.church;
      },
      [getChurchByIdForEdit.rejected]: (state, action) => {
         state.churchForEdit = null;
      },
      [getChurchWFavoriteById.fulfilled]: (state, action) => {
         state.church = action.payload.church;
      },
      [getChurchWFavoriteById.rejected]: (state, action) => {
         state.church = null;
      },
      [getChurchesBySearch.fulfilled]: (state, action) => {
         state.searchResults = action.payload.churches;
      },
      [getChurchesBySearch.rejected]: (state, action) => {
         state.searchResults = null;
      },
      [getChurchesBySearchVector.fulfilled]: (state, action) => {
         state.searchResults = action.payload.churches;
      },
      [getChurchesBySearchVector.rejected]: (state, action) => {
         state.searchResults = null;
      },
      [updateChurchByAdmin.fulfilled]: (state, action) => {
         state.church = action.payload.church;
      },
      [updateChurchByAdmin.rejected]: (state, action) => {
         state.church = null;
      },
   },
   reducers: {
      clearChurchDetailData: () => {
         return { church: null };
      },
      clearChurchesData: () => {
         return { churches: null };
      },
   },
});

const { reducer, actions } = churchSlice;
export const { clearChurchDetailData, clearChurchesData } = actions;
export default reducer;
