import api from "./api";

const getDiscoverPastors = async (param) => {
   let paramStr = "";
   if(param) {
      Object.keys(param).forEach(key => {
         paramStr += `${key}=${param[key]}&`
      })
   }
   const response = await api.get(`/discover/pastors?${paramStr}`);
   return response;
};

const addDiscoverPastors = async (data) => {
   const response = await api.post(`/discover/pastors`, { pastor: data });
   return response;
};

const deleteDiscoverPastors = async (id) => {
   const response = await api.delete(`/discover/pastors?pastorId=${id}`);
   return response;
};

const getDiscoverChurches = async () => {
   const response = await api.get(`/discover/churches`);
   return response;
};
const getDiscoverTopSearches = async (param) => {
   let paramStr = "";
   if(param) {
      Object.keys(param).forEach(key => {
         paramStr += `${key}=${param[key]}&`
      })
   }
   const response = await api.get(`/discover/topSearches?${paramStr}`);
   return response;
};

const addDiscoverTopSearches = async (data) => {
   const response = await api.post(`/discover/topSearches`, { topSearch: data });
   return response;
};

const deleteDiscoverTopSearches = async (id) => {
   const response = await api.delete(`/discover/topSearches?topSearchId=${id}`);
   return response;
};



const discoverService = {
   getDiscoverPastors,
   addDiscoverPastors,
   deleteDiscoverPastors,
   getDiscoverChurches,
   getDiscoverTopSearches,
   addDiscoverTopSearches,
   deleteDiscoverTopSearches
};

export default discoverService;
