import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { clearChurchesData } from "../slices/church.slice";
import {
  getFavoriteChurches,
  setEmptyFavoriteChurches,
} from "../slices/favorite.slice";
import Loading from "./Animations/Loading";
import { getImageLink } from "../lib/helper";
import ShadowCard from "./Card/ShadowCard";
import { Link } from "react-router-dom";
import MagOne from "../assets/icons/Mag-1.png";
import PaginatingButtons from "./Buttons/PaginatingButtons";

const DEFAULT_LIMIT = 4;

const Favourites = ({ switchRecLive }) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const churches = useSelector((state) => state.favoriteData.favorites);
  const demoChurches = useSelector(
    (state) => state.churchData.recommendedChurches
  );
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (currentUser) {
      dispatch(
        getFavoriteChurches({
          userId: currentUser.id,
          page: page - 1,
          size: DEFAULT_LIMIT,
        })
      );
      return () => {
        dispatch(clearChurchesData());
      };
    } else {
      dispatch(setEmptyFavoriteChurches({}));
      return () => {
        dispatch(clearChurchesData());
      };
    }
  }, [dispatch, page]);

  const renderSignUp = () => {
    if (!currentUser) {
      return (
        <div className="flex flex-col justify-center -mt-52 mb-44 z-40 relative">
          <div className="text-center text-2xl font-bold">
            Quickly access your favorite churches!
          </div>
          <div className="flex justify-center">
            <Link to="/signup">
              <button className="rounded-lg bg-cta hover:bg-button text-white w-24 py-1 mt-2 text-center">
                Sign Up
              </button>
            </Link>
          </div>
        </div>
      );
    } else if (currentUser && churches.data.length == 0) {
      return (
        <div className="flex flex-col justify-center -mt-52 mb-44 z-40 relative">
          <div className="text-center text-2xl font-bold">
            Add churches to your favorites!
          </div>
          <div className="flex justify-center">
            <Link to="/search/churches">
              <button className="rounded-lg bg-cta hover:bg-button text-white w-24 py-1 mt-2 text-center">
                Find Churches
              </button>
            </Link>
          </div>
        </div>
      );
    } else {
      return;
    }
  };

  const renderShadowCard = (church, target = null) => {
    let redirectTo = `/church/${church.id}`;
    if (target) {
      redirectTo = target;
    }
    return (
      <ShadowCard>
        <Link to={`/church/${church.id}`} key={church.id}>
          <img
            className="object-cover w-full h-44"
            src={
              getImageLink(church.image) ||
              "https://images.unsplash.com/photo-1438032005730-c779502df39b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
            }
            alt={church.name}
          />

          <div className="pb-4 text-left mx-4 col-span-1 h-28 flex flex-col justify-between">
            <div>
              <h5 className="font-medium leading-tight text-xl text-gray-800 my-2 capitalize">
                {church.name}
              </h5>
            </div>
            <div>
              <span className="text-sm text-gray-700 capitalize">
                {church?.city ? church?.city + "," : ""}{" "}
                {church.state && church.state.abbreviation}
              </span>
            </div>
          </div>
        </Link>
      </ShadowCard>
    );
  };

  const renderDummyCards = () => {
    while (!churches) {
      return <div>Loading...</div>;
    }
    return (
      <>
        {churches.data.map((churchEntry) => {
          const { church } = churchEntry || {};
          return renderShadowCard(church);
        })}

        {Array(4 - churches.data.length)
          .fill()
          .map(() => {
            return (
              <div className="flex border-4 border-dashed rounded-xl border-neutral-200">
                <div className="flex items-center justify-center flex-1 bg- overflow-hidden w-72 md:w-full">
                  <img src={MagOne} width={110} />
                </div>
              </div>
            );
          })}
      </>
    );
  };

  const renderInnerComponents = () => {
    if (!currentUser) {
      renderSignUp();
    }

    if (!churches || churches.data.length == 0) {
      while (!demoChurches) {
        return <Loading size={6} />;
      }
      return demoChurches.data.map((demoChurch) => (
        <div className="z-10 relative blur-sm">
          <div className="opacity-30">
            <ShadowCard key={demoChurch.id}>
              <Link to={`/church/${demoChurch.id}`}>
                <img
                  className="object-cover w-full h-44"
                  src={
                    getImageLink(demoChurch.image) ||
                    "https://images.unsplash.com/photo-1438032005730-c779502df39b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                  }
                  alt={demoChurch.name}
                  height="400"
                  width="400"
                />

                <div className="pb-4 text-left mx-4 col-span-1 h-28 flex flex-col justify-between">
                  <div>
                    <h5 className="font-medium leading-tight text-xl text-gray-800 my-2 capitalize">
                      {demoChurch.name.toLowerCase()}
                    </h5>
                  </div>
                  <span className="text-sm text-gray-700 capitalize">
                    {demoChurch?.city
                      ? demoChurch?.city.toLowerCase() + ","
                      : ""}{" "}
                    {demoChurch.state && demoChurch.state.abbreviation}
                  </span>
                </div>
              </Link>
            </ShadowCard>
          </div>
        </div>
      ));
    }
  };

  const paginationButtons = () => {
    while (!churches) {
      return <div></div>;
    }
    return (
      <PaginatingButtons
        page={page}
        setPage={setPage}
        numberOfPages={churches.numberOfPages}
      />
    );
  };

  const mapping = () => {
    while (currentUser && !churches) {
      return <Loading size={6} />;
    }
    return (
      <div className="md:mt-10 mx-auto max-w-7xl px-0 md:px-4">
        <div className="flex flex-row mx-2 items-center gap-5">
          <h1 className="text-2xl md:text-3xl font-bold p-2 font-roboto-slab">
            Favorites
          </h1>
          {paginationButtons()}
          {switchRecLive}
        </div>
        <div className="flex md:grid md:grid-cols-4 overflow-x-auto hide-scroll-bar sc relative gap-5 p-4 pb-4">
          {renderInnerComponents()}
          {renderDummyCards()}
        </div>
        {renderSignUp()}
      </div>
    );
  };

  return <div>{mapping()}</div>;
};

export default Favourites;
