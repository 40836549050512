import { Link } from "react-router-dom";
import PictureText from "./PictureText";
import NewYorkImg from "../assets/cityicons/nyc_icon.png";
import LAImg from "../assets/cityicons/la_icon.png";
import ChicagoImg from "../assets/cityicons/chicago_icon.png";
import AustinImage from "../assets/cityicons/austin_icon.png";
import MiamiImg from "../assets/cityicons/miami_icon.png";
import HoustonImg from "../assets/cityicons/houston_icon.png";
import AtlantaImg from "../assets/cityicons/atlanta_icon.png";
import DallasImg from "../assets/cityicons/dallas_icon.png";
import NashvilleImg from "../assets/cityicons/nashville_icon.png";
import PhiladelphiaImg from "../assets/cityicons/philadelphia_icon.png";

const cities = [
  {
    name: "new york",
    image: NewYorkImg,
  },

  {
    name: "miami",
    image: MiamiImg,
  },
  {
    name: "los angeles",
    image: LAImg,
  },

  {
    name: "Houston",
    image: HoustonImg,
  },
  {
    name: "chicago",
    image: ChicagoImg,
  },

  {
    name: "atlanta",
    image: AtlantaImg,
  },

  {
    name: "Austin",
    image: AustinImage,
  },
  {
    name: "dallas",
    image: DallasImg,
  },
  {
    name: "Nashville",
    image: NashvilleImg,
  },
  {
    name: "Philadelphia",
    image: PhiladelphiaImg,
  },
];

const MajorCities = () => {
  return (
    <div className="mt-10 mx-auto max-w-7xl px-0 md:px-4 overflow-x-auto hide-scroll-bar">
      <div className="flex flex-row mx-2 items-center gap-5">
        <h1 className="text-2xl md:text-3xl font-bold p-2 font-roboto-slab">
          Major Cities
        </h1>
      </div>
      <div className="grid grid-flow-col grid-cols-2 grid-rows-5 items-end md:grid-cols-5 md:grid-rows-2 gap-5 px-8">
        {cities.map((city, idx) => {
          return (
            <Link
              to={`/search/churches?type=city&value=${city.name.toLowerCase()}`}
              key={idx}
            >
              <PictureText city={city.name} image={city.image} />
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default MajorCities;
