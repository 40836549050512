import React from "react";

const BaseAlert = (props) => {
   function alert() {
      switch (props.type) {
         case "danger":
            return (
               <div
                  className="bg-red-100 rounded-lg py-5 px-6 text-base text-red-700 mb-3"
                  role="alert"
               >
                  {props.text}
               </div>
            );
         case "primary":
            return (
               <div
                  className="bg-blue-100 rounded-lg py-5 px-6 text-base text-blue-700 mb-3"
                  role="alert"
               >
                  {props.text}
               </div>
            );
         case "warning":
            return (
               <div
                  className="bg-yellow-100 rounded-lg py-5 px-6 text-base text-yellow-700 mb-3"
                  role="alert"
               >
                  {props.text}
               </div>
            );
         case "success":
            return (
               <div
                  className="bg-green-100 rounded-lg py-5 px-6 text-base text-green-700 mb-3"
                  role="alert"
               >
                  {props.text}
               </div>
            );
         case "light":
            return (
               <div
                  className="bg-gray-50 rounded-lg py-5 px-6 text-base text-gray-500 mb-3"
                  role="alert"
                  {...props}
               >
                  {props.text}
               </div>
            );
         default:
            return (
               <div
                  className="bg-gray-300 rounded-lg py-5 px-6 text-base text-gray-800 mb-3"
                  role="alert"
               >
                  {props.text}
               </div>
            );
      }
   }

   return alert();
};

export default BaseAlert;
