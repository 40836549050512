import PaginatingButtons from "./Buttons/PaginatingButtons";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  clearChurchesData,
  getRecommendedChurchesBySize,
} from "../slices/church.slice";
import _ from "lodash";
import Loading from "./Animations/Loading";
import { getImageLink } from "../lib/helper";
import ShadowCard from "./Card/ShadowCard";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DEFAULT_LIMIT = 4;

const LiveNow = ({ switchRecLive }) => {
  const dispatch = useDispatch();
  const churches = useSelector((state) => state.churchData.recommendedChurches);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(
      getRecommendedChurchesBySize({ page: page - 1, size: DEFAULT_LIMIT })
    );
    return () => {
      dispatch(clearChurchesData);
    };
  }, [dispatch, page]);

  const paginationButtons = () => {
    while (!churches) {
      return <div></div>;
    }
    return (
      <PaginatingButtons
        page={page}
        setPage={setPage}
        numberOfPages={churches.numberOfPages}
      />
    );
  };

  const mapping = () => {
    while (!churches) {
      return <Loading size={6} />;
    }
    return (
      <div className="mt-5 md:mt-10 mx-auto max-w-7xl md:px-4">
        <div className="flex flex-row mx-2 items-center gap-5">
          <h1 className="text-2xl md:text-3xl font-bold p-2 font-roboto-slab">
            Recommended
          </h1>
          {paginationButtons()}
          {switchRecLive}
          {/* TODO */}
          {/* <Link to={`/search/churches?type=discover`}>
            <button className=" bg-button rounded-xl p-2 px-6 text-white text-sm shadow ">
              Discover
            </button>
          </Link> */}
        </div>
        <div className="flex md:grid md:grid-cols-4 overflow-x-auto hide-scroll-bar sc relative gap-5 m-4 pb-4">
          {churches.data &&
            _.shuffle(churches.data).map((church) => {
              return (
                <ShadowCard key={church.id}>
                  <Link to={`/church/${church.id}`}>
                    <img
                      className="object-cover w-full h-44"
                      src={
                        getImageLink(church.image) ||
                        "https://images.unsplash.com/photo-1438032005730-c779502df39b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
                      }
                      alt={church.name}
                      height="400"
                      width="400"
                    />

                    <div className="pb-4 text-left mx-4 col-span-1 h-28 flex flex-col justify-between">
                      <div>
                        <h5 className="font-medium leading-tight text-xl text-gray-800 my-2 capitalize">
                          {church.name.toLowerCase()}
                        </h5>
                      </div>
                      <span className="text-sm text-gray-700 capitalize">
                        {church?.city ? church?.city.toLowerCase() + "," : ""}{" "}
                        {church.state && church.state.abbreviation}
                      </span>
                    </div>
                  </Link>
                </ShadowCard>
              );
            })}
        </div>
      </div>
    );
  };

  return <div>{mapping()}</div>;
};

export default LiveNow;
