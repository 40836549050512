import styles from "../styles/Layout.module.css";

const Layout = ({ children }) => {
   return (
      <div className="mx-auto max-w-7xl px-2 md:px-4">
         <main className={styles.main}>{children}</main>
      </div>
   );
};

export default Layout;
