import React from "react";

const PaginatingButtons = ({ page, setPage, numberOfPages }) => {
  const nextPage = () => {
    if (numberOfPages > page) {
      setPage(page + 1);
    }
  };

  const prevPage = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <div className="flex flex-row gap-1">
      <button
        className={`bg-button p-1 px-4 text-white text-sm shadow rounded-tl-lg rounded-bl-lg ${
          page > 1 ? "" : "opacity-50"
        }`}
        onClick={() => prevPage()}
      >
        Back
      </button>
      <button
        className={`bg-button p-1 px-4 text-white text-sm shadow rounded-tr-lg rounded-br-lg ${
          numberOfPages > page ? "" : "opacity-50"
        }`}
        onClick={() => nextPage()}
      >
        Next
      </button>
    </div>
  );
};

export default PaginatingButtons;
