import MagOne from "../assets/icons/Mag-1.png";

const ChurchFinderCTA = () => {
  return (
    <div className="md:mt-10 mx-auto max-w-7xl px-0 md:px-4">
      <div className="flex flex-row justify-between bg-footer rounded-lg mt-8 mx-8 py-4 px-8">
        <div className="flex flex-col align-middle justify-center self-center items-center">
          <div className="font-roboto-slab text-white text-xl ">
            Not sure where to start? Try our{" "}
            <a href="/search/churches">
              <span
                style={{ whiteSpace: "nowrap" }}
                className="rounded-lg bg-cta hover:bg-button text-white w-24 py-1 px-2 mt-2 mr-2 text-center"
              >
                Church Finder Tool
              </span>
            </a>
            !
          </div>
        </div>
        <div> 
          {" "}
          <img src={MagOne} width={110} />
        </div>
      </div>
    </div>
  );
};

export default ChurchFinderCTA;
