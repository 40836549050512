import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchFilterInput from "../Fields/SearchFilterInput";

const MobileHeader = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [filterOpt, setFilterOpt] = useState("");

  const makeSearchQuery = () => {
    if (filterOpt === "" || filterOpt === "all") {
      return `/search/churches?value=${searchTerm}`;
    } else {
      return `/search/churches?filter=${filterOpt}&value=${searchTerm}`;
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    return navigate(makeSearchQuery());
  };

  return (
    <div className="px-4">
      <SearchFilterInput
        setSearchTerm={setSearchTerm}
        onSubmit={onSubmit}
        searchQuery={makeSearchQuery()}
        setFilterOpt={setFilterOpt}
        filterOpt={filterOpt}
      />
    </div>
  );
};

export default MobileHeader;
