import React from "react";
import Layout from "../components/Layout";
import PlayerImage from "../assets/icons/icon1.png";
import LanguageImage from "../assets/icons/17languages.png";
import ChurchImage from "../assets/icons/10000churches.png";
import CountryImage from "../assets/icons/50states.png";
import CrossImage from "../assets/icons/39denoms.png";
import WorldImage from "../assets/icons/3countries.png";
import { languages } from "../lib/helper";

const ThankYou = () => {
  return (
    <div className="w-full px-8 md:px-20">
      <div className="grid grid-cols-2 mt-4">
        <div className="text-3xl md:text-7xl md:ml-8 font-bold leading-tight font-roboto-slab italic">
          Connecting People to Church.
        </div>
        <div className="bg-about bg-cover bg-right-bottom"></div>
      </div>
      {/* <div className="h-12 md:h-96 w-full bg-black my-8">Hello</div> */}
      <div className="font-serif text-sm md:text-base mt-4">
        <div className="my-8 md:text-3xl text-center mb-4 font-semibold font-roboto-slab">
          Thank you for your donation!
        </div>
        <div className="mb-4">
          Stream Church exists to help bridge the gap between people and Church,
          and we wouldn't be able to accomplish that mission without people like
          you. Please be sure to let us know of any improvments that we can make
          to the platform, as well as ways in which StreamChurch has been a
          blessing to you! We pray that God uses this platform to extend the
          Gospel in ways we could've never imagined.
        </div>
      </div>
      <div className="my-8 flex justify-center">
        <div className="md:w-3/4 text-xl font-bold text-center md:mx-48 font-roboto-slab italic">
          We exist to help you know God through the hearing of His Word.
        </div>
      </div>
    </div>
  );
};

export default ThankYou;
