import SmallCard from "./SmallCard";
import { Link } from "react-router-dom";
import { useState } from "react";
import { denominations, ucword } from "../lib/helper";
import useIsMobile from "../hooks/useIsMobile";
import PaginatingButtons from "./Buttons/PaginatingButtons";
import { number } from "yup";

const DEFAULT_LIMIT = 4;

const paginate = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
};

const Denominations = () => {
  const [page, setPage] = useState(1);
  const [n, setN] = useState(4);

  const numberOfPages = Math.ceil(denominations.length / DEFAULT_LIMIT);
  const isMobile = useIsMobile();
  const nextPage = () => {
    setPage(page + 1);
  };
  const prevPage = () => {
    setPage(page - 1);
  };
  const showDenominations = () => {
    n === denominations.length ? setN(4) : setN(denominations.length);
  };
  return (
    <div className="mt-5 md:mt-10 mx-auto max-w-7xl md:px-4">
      <div className="flex flex-row mx-2 items-center gap-5">
        <h1 className="text-2xl md:text-3xl font-bold p-2 font-roboto-slab">
          Denominations
        </h1>
        <PaginatingButtons
          page={page}
          setPage={setPage}
          numberOfPages={numberOfPages}
        />
      </div>
      <div className="relative px-5">
        <div className="overflow-x-scroll hide-scroll-bar  hide-scroll-bar grid sm:grid-cols-2  md:grid-cols-4 gap-5">
          {isMobile
            ? denominations.slice(0, n).map((denom, idx) => {
                return (
                  <Link
                    to={`/search/churches?filter=denomination&type=denom&value=${denom.value.toLowerCase()}`}
                    key={idx}
                  >
                    <SmallCard name={ucword(denom.name)} />
                  </Link>
                );
              })
            : paginate(denominations, DEFAULT_LIMIT, page).map((denom, idx) => {
                return (
                  <Link
                    to={`/search/churches?filter=denomination&type=denom&value=${denom.value.toLowerCase()}`}
                    key={idx}
                  >
                    <SmallCard name={ucword(denom.name)} />
                  </Link>
                );
              })}
          <div className="flex justify-center md:hidden">
            <div className="text-button" onClick={() => showDenominations()}>
              {n === denominations.length ? "Less" : "More"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Denominations;
