import React from "react";
import Spinner from "../Animations/Spinner";

const BaseButton = (props) => {
   switch (props.kind) {
      case "primary":
         return (
            <button
               {...props}
               type={props.type || "button"}
               className="px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex align-center"
            >
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
      case "dark":
         return (
            <button
               {...props}
               type={props.type || "button"}
               className="px-6 py-2.5 bg-gray-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg focus:bg-gray-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out flex align-center"
            >
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
      case "danger":
         return (
            <button
               {...props}
               type={props.type || "button"}
               className="inline-block px-6 py-2.5 bg-red-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-700 active:shadow-lg transition duration-150 ease-in-out"
            >
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
      case "light":
         return (
            <button
               {...props}
               type={props.type || "button"}
               className="inline-block px-6 py-2.5 bg-gray-200 text-gray-700 font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-300 hover:shadow-lg focus:bg-gray-300 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-400 active:shadow-lg transition duration-150 ease-in-out flex align-center"
            >
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
      case "warning":
         return (
            <button
               {...props}
               type={props.type || "button"}
               className="px-6 py-2.5 bg-yellow-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-yellow-600 hover:shadow-lg focus:bg-yellow-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-yellow-700 active:shadow-lg transition duration-150 ease-in-out flex align-center"
            >
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
      case "success":
         return (
            <button
               {...props}
               type={props.type || "button"}
               className="px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out flex align-center"
            >
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
      case "info":
         return (
            <button
               {...props}
               type={props.type || "button"}
               className="px-6 py-2.5 bg-blue-400 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-500 hover:shadow-lg focus:bg-blue-500 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-600 active:shadow-lg transition duration-150 ease-in-out flex align-center"
            >
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
      case "custom":
         return (
            <button {...props} type={props.type || "button"}>
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
      default:
         return (
            <button
               {...props}
               type={props.type || "button"}
               className="px-6 py-2.5 bg-gray-700 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-gray-600 hover:shadow-lg focus:bg-gray-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-gray-800 active:shadow-lg transition duration-150 ease-in-out flex align-center"
            >
               {props.loading ? <Spinner /> : ""}
               {props.text}
            </button>
         );
   }
};

export default BaseButton;
