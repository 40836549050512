const ShadowCard = ({ children }) => {
  return (
    <div className="flex flex-col">
      <div className="flex-1 rounded-xl border-x border-b border-neutral-200 overflow-hidden w-72 md:w-full">
        {children}
      </div>
    </div>
  );
};

export default ShadowCard;
