const PictureText = ({ city, image }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="">
        <img className=" w-28 md:w-20" src={image} alt={city} />
      </div>
      <h6 className="p-2 text-xl md:text-lg capitalize text-center font-roboto-slab">
        {city}
      </h6>
    </div>
  );
};

export default PictureText;
