import { Link } from "react-router-dom";
import { getImageLink, ucword } from "../lib/helper";

const SearchCard = ({
  id,
  name,
  city,
  state,
  pastor,
  image,
  zipcode,
  miles = null
}) => {
  const milesToDisplay = miles ? (miles <= 1 ? "~1" : miles) : null;
  return (
    <div className="bg-gray-100 rounded-xl m-2">
      {miles && miles != 0 && (
        <div className="church-miles-corner">{milesToDisplay} Mi</div>
      )}
      <Link to={`/church/${id}`}>
        <div className="flex flex-row cursor-pointer">
          <div className="w-1/2 self-center p-2">
            <img
              className="object-cover w-full h-28 rounded"
              src={getImageLink(image)}
              alt={name}
            />
          </div>
          <div className="w-1/2 p-2 py-5 flex flex-col justify-between">
            <div>
              <h1 className="text-md line-clamp-2 ">{name}</h1>
            </div>
            <div>
              <h1 className="text-xs">
                {city}, {state} {zipcode}
              </h1>
              {pastor && (
                <h1 className="text-xs truncate">Pastor: {ucword(pastor)}</h1>
              )}
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SearchCard;
