import api, { API_URL } from "./api";


const getCitySuggestions = async (keyword) => {
   const response = await api.get(
      `/suggestions/city?keyword=${keyword}`
   );
   return response.data;
};

const getStateSuggestions = async (keyword) => {
   const response = await api.get(
      `/suggestions/state?keyword=${keyword}`
   );
   return response.data;
};

const getPastorSuggestionsV2 = async (keyword) => {
   const response = await api.get(
      `/v2/suggestions/pastor?keyword=${keyword}`
   );
   return response.data;
};

const commonService = {
   getCitySuggestions,
   getStateSuggestions,
   getPastorSuggestionsV2
};

export default commonService;
