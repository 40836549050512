import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message.js";
import AuthService from "../services/auth.service";

const user = JSON.parse(localStorage.getItem("user"));

export const register = createAsyncThunk(
   "auth/register",
   async ({ username, email, password }, thunkAPI) => {
      try {
         const data = await AuthService.register(username, email, password);

         return { user: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();

         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const login = createAsyncThunk(
   "auth/login",
   async ({ loginId, password }, thunkAPI) => {
      try {
         const data = await AuthService.login(loginId, password);
         return { user: data };
      } catch (error) {
         const message =
            (error.response && error.response.data) ||
            error.message ||
            error.toString();
         thunkAPI.dispatch(setMessage(message));
         return thunkAPI.rejectWithValue();
      }
   }
);

export const logout = createAsyncThunk("auth/logout", async () => {
   await AuthService.logout();
});

const initialState = user
   ? { isLoggedIn: true, user }
   : { isLoggedIn: false, user: null };

const authSlice = createSlice({
   name: "auth",
   initialState,
   extraReducers: {
      [register.fulfilled]: (state, action) => {
         state.isLoggedIn = false;
         state.user = action.payload.user;
      },
      [register.rejected]: (state, action) => {
         state.isLoggedIn = false;
      },
      [login.fulfilled]: (state, action) => {
         state.isLoggedIn = true;
         state.user = action.payload.user;
      },
      [login.rejected]: (state, action) => {
         state.isLoggedIn = false;
         state.user = null;
      },
      [logout.fulfilled]: (state, action) => {
         state.isLoggedIn = false;
         state.user = null;
      },
   },
   // non - async
   reducers: {
      refreshTokenAction: (state, action) => {
         state.user = { ...state.user, accessToken: action.payload };
      },
      setCurrentUserAction: (state, action) => {
         state.user = action.payload.user;
      },
   },
});

const { actions, reducer } = authSlice;
export const { refreshTokenAction, setCurrentUserAction } = actions;
export default reducer;
