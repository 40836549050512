import React, { useState } from "react";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";

const DonationForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Added state variable

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements || isSubmitting) {
      return;
    }

    setIsSubmitting(true); // Disable the submit button

    const cardElement = elements.getElement(CardElement);

    const { token } = await stripe.createToken(cardElement);
    console.log(token);
    if (token) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/create-payment-intent`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              token: token.id,
              amount: amount * 100, // Convert amount to cents
            }),
          }
        );

        const data = await response.json();
        if (data.error) {
          console.error(data.error);
        } else {
          console.log("Donation successful:", data);
          window.location.href = "/thank-you";
        }
      } catch (error) {
        console.error("Error processing donation:", error);
      }
      setIsSubmitting(false); // Re-enable the submit button
    }
  };

  return (
    <div className="mt-10 mx-auto max-w-7xl px-0 md:px-4">
      <h1 className="text-lg text-center font-roboto-slab">
        <span className="font-bold">
          StreamChurch will always be free for users
        </span>
        , but does require maintenance costs to keep running.<br></br>{" "}
        Prayerfully consider donating!
      </h1>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col md:flex-row justify-between items-center rounded-lg mt-8 mx-8 py-4 px-12 bg-white shadow-md"
      >
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Donation amount"
          className="w-full md:flex-1 border border-gray-300 rounded-lg p-2 mb-4 md:mb-0 md:mr-4 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        />
        <div className="w-full md:flex-1 border border-gray-300 rounded-lg p-2 mb-4 md:mb-0 md:mr-4 h-10">
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
        <button
          type="submit"
          disabled={!stripe || isSubmitting} // Use isSubmitting to control the disabled state
          id="submit-button"
          className="w-full md:w-auto rounded-lg bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 mt-2 md:mt-0 text-center transition duration-300 ease-in-out"
        >
          Donate
        </button>
      </form>
    </div>
  );
};

export default DonationForm;
