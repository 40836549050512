import React from "react";

const Label = (props) => {
   const block = props.block || "block";
   return (
      <label
         className={"form-label font-medium block m-2 text-gray-600 " + block}
         htmlFor={props.label}
      >
         {props.label}
      </label>
   );
};

export default Label;
