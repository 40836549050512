import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import FieldFormik from "../components/Fields/FieldFormik";
import BaseButton from "../components/Buttons/BaseButton";
import Label from "../components/Fields/Label";
import Layout from "../components/Layout";
import emailService from "../services/email.service";
import { contactSchema } from "../lib/validation/inputSchema";
import ReactRecaptcha3 from "react-google-recaptcha3";
import ReactGA from "react-ga";

const SITE_RECAPTCHA3_SITEKEY = "6LfDwigiAAAAAJbtpSRjMWz9VxyPJ-0XH9ChYb2m";

const ContactUs = ({ subject }) => {
  const [loading, setLoading] = useState(false);
  const initValues = {
    name: "",
    email: "",
    message: "",
    subject: "",
  };

  useEffect(() => {
    ReactRecaptcha3.init(SITE_RECAPTCHA3_SITEKEY).then((status) => {
      console.log("Recaptcha initialized: " + status);
    });
  }, []);

  const onSend = async (values) => {
    setLoading(true);
    try {
      const token = await ReactRecaptcha3.getToken();

      const response = await emailService.sendEmail(values, token);
      if (response.status === 200) {
        toast.success(response.data.message);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  return (
    <Layout>
      <h1 className="text-3xl py-5 text-center">Contact Us</h1>
      <Formik
        initialValues={initValues}
        validationSchema={contactSchema}
        onSubmit={(values) => {
          onSend(values);
        }}
      >
        {({ resetForm }) => (
          <Form>
            <div className="form-group">
              <Label label="Name" />
              <FieldFormik id="name" name="name" />
              <ErrorMessage
                component="a"
                className="text-red-500 text-sm"
                name="name"
              />
            </div>
            <div className="form-group">
              <Label label="Email" htmlFor="Email" />
              <FieldFormik id="email" name="email" />
              <ErrorMessage
                component="a"
                className="text-red-500 text-sm"
                name="email"
              />
            </div>
            <div className="form-group">
              <Label label="Subject" htmlFor="Subject" />
              <FieldFormik id="subject" name="subject" />
              <ErrorMessage
                component="a"
                className="text-red-500 text-sm"
                name="subject"
              />
            </div>
            <div className="form-group">
              <Label label="Message" />
              <FieldFormik as="textarea" rows="5" id="message" name="message" />
            </div>
            <ErrorMessage
              component="a"
              className="text-red-500 text-sm"
              name="message"
            />

            <div className="mt-8 flex flex-wrap gap-x-3">
              <BaseButton
                type="submit"
                disabled={loading}
                loading={loading ? 1 : 0}
                text="Submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default ContactUs;
