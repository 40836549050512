import React from "react";
import SCLogo from "../assets/SClogo-black.png";
import PressRelease from "../assets/misc/SC-pressrelease.png";

const PressKit = () => {
  return (
    <div className="flex flex-col gap-4 m-8 mx-16">
      <div className="flex flex-col text-center align-middle">
        <div className="font-roboto-slab text-7xl">Press Kit</div>
        <div>
          Looking for info to share StreamChurch with the world? We’ve got what
          you’re looking for.
        </div>
      </div>
      <div className="bg-mobile bg-bottom bg-cover w-full h-48 my-16"></div>
      <div className="flex flex-col gap-4 text-center mb-8">
        <div className="text-3xl font-roboto-slab"> Our Brand Resources</div>
        <div>
          From photography and video assets, to logos, we got you. Download our
          entire press kit, or specific assets here.
        </div>
        <div>
          <button className="rounded-lg bg-button text-white px-8 py-2 mt-2 text-center ">
            Download All
          </button>
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <div className="mb-8">
          Stream Church exists to help bridge the gap between people and Church.
          Our desire is to make it easier for people from all demographics to
          explore, visit, search and get connected to Church. Stream Church was
          designed and created for you; for the person who finds church
          intimidating, for the senior citizen who finds it difficult to search
          for church on google or YouTube, for the millions of people who move
          cities each year and have no idea how to start their church search. No
          matter who you are or what your background is Stream Church is there
          to make it easier for you to find Church. Stream Church has a database
          of over 10,000 churches with search-ability of up to 35 datapoint
          including but not limited to: Pastor Name, Location, Church Name,
          Denomination, etc.
        </div>
        <div className=" font-roboto-slab text-xl">Contact Info:</div>
        <div>General Inquiries: hello@streamchurch.net</div>
      </div>
      <div className="flex justify-center flex-col md:flex-row col-span-2 gap-4 h-auto ">
        <div className="w-full md:w-1/2">
          <div className="flex flex-col justify-center border h-96 bg-sc-logo bg-contain bg-no-repeat bg-center"></div>
          <div className="text-xl md:my-4">
            {" "}
            Media Assets | Brand + Style Guides
          </div>
          <button className="rounded-lg bg-button text-white px-8 py-2 mt-2 text-center ">
            Download
          </button>
        </div>
        <div className="w-full md:w-1/2">
          <div className="flex flex-col justify-center border h-96 w-full p-4 bg-press_release bg-contain bg-no-repeat bg-center"></div>
          <div className="text-xl md:my-4">Press Release</div>
          <a
            href="https://streamchurch-bucket.s3.us-west-1.amazonaws.com/SC-pressrelease.pdf"
            download
          >
            <button className="rounded-lg bg-button text-white px-8 py-2 mt-2 text-center ">
              Download
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PressKit;
