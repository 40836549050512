import React from "react";

const Spinner = ({ size }) => {
   return (
      <div
         className={
            `spinner-border animate-spin inline-block border-3 rounded-full mr-2 ` +
            `w-${size || 4} h-${size || 4}`
         }
         role="status"
      >
         <span className="visually-hidden">Loading...</span>
      </div>
   );
};

export default Spinner;
