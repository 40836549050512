import React, {
  memo,
  useState,
  useEffect} from "react";

const RangeSlider = memo(
  ({ classes, label, onChange, value, isDisabled, ...sliderProps }) => {
    const [sliderVal, setSliderVal] = useState(0);
    const [mouseState, setMouseState] = useState(null);

    useEffect(() => {
      setSliderVal(value);
    }, [value]);

    const changeCallback = e => {
      setSliderVal(e.target.value);
    };

    useEffect(() => {
      if (mouseState === "up") {
        onChange(sliderVal);
      }
    }, [mouseState]);

    return (
      <div className="range-slider" style={{ width: '100%', padding: '5px'}}>
        <p>{label} : {sliderVal} Miles</p>
        <input
          disabled={isDisabled}
          type="range"
          value={sliderVal}
          {...sliderProps}
          className={`slider ${classes}`}
          id="myRange"
          onChange={changeCallback}
          onMouseDown={() => setMouseState("down")}
          onMouseUp={() => setMouseState("up")}
          style={{ width: "100%" }}
        />
      </div>
    );
  }
);

export default RangeSlider;
