import React from "react";

const Divider = ({ children }) => {
  return (
    <div className="my-10 w-full border border-gray-200 relative">
      <div className="absolute left-1/2 -top-1/2  transform -translate-y-1/2 -translate-x-1/2">
        <div className="px-5 bg-footer">{children}</div>
      </div>
    </div>
  );
};

export default Divider;
