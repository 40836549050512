import React from "react";
import JobCard from "../components/Card/JobCard";

const Careers = () => {
  return (
    <div className="w-full px-8 md:px-20">
      <div className="grid grid-cols-2 mt-4">
        <div>
          <div className="text-3xl md:text-7xl md:ml-8 font-bold leading-tight font-roboto-slab italic">
            Careers at StreamChurch
          </div>
        </div>
        <div className="bg-about bg-cover bg-right-bottom"></div>
      </div>
      <div className="mt-8 md:m-8">
        <div className="text-xl">
          It is our mission to help connect people to church. This is a huge
          undertaking and we have many career opportunities available and need
          your help in order to help accomplish this mission! <br />
          <span className="font-roboto-slab italic flex justify-center">
            <br /> A mission like ours needs partners like you. <br />
          </span>{" "}
          <br />
          At Stream Church we are committed to help do our part in helping the
          world hear the Gospel. We believe that God has placed the church at
          the forefront of this mission. We value each and every team member and
          want to make it part of our mission to help them achieve their career
          goals.
        </div>
        <div className="mt-8">
          <div className="text-xl font-roboto-slab bold">How We Work</div>
          <div>
            How we work At Stream Church our Team Members currently work 100%
            remote. Our goal is to be respectful of your time and life outside
            of work. We utilize a R.O.W.E. work environment, this stands for
            Results Oriented Work Environment. Outside of scheduled meetings,
            you are free to make your own schedule in order to accomplish your
            assigned tasks.
          </div>
        </div>
      </div>
      <div className="m-8">
        <div className="text-xl font-roboto-slab mb-4">
          Committee and Advisory:
        </div>
        <div className="grid grid-cols-2 gap-2">
          <JobCard position={"Advisory Comittee Member"} />
          <JobCard position={"Church Data Auditor"} />
          <JobCard position={"Church Ambassador"} />
        </div>
      </div>
      <div className="m-8">
        <div className="text-xl font-roboto-slab mb-4">Full-Time:</div>
        <div className="grid grid-cols-2 gap-2">
          <JobCard position={"Database Analyst (Churches)"} />
          <JobCard position={"Partnership Coordinator (Churches)"} />
          <JobCard position={"Senior Marketing Director"} />
          <JobCard position={"Public Relations Director"} />
        </div>
      </div>
      <div className="m-8">
        <div className="text-xl font-roboto-slab mb-4">Part-Time/Contract:</div>
        <div className="grid grid-cols-2 gap-2">
          <JobCard position={"Communications Coordinator"} />
          <JobCard position={"Administrative Coordinator"} />
          <JobCard position={"Summer Internship -- 2023"} />
        </div>
      </div>
      <div className="m-8 text-sm">
        <span className=" font-bold ">Equal Opportunity Statement:</span> <br />{" "}
        Qualified applicants will receive consideration for employment without
        regard to race, color, religion, sex, sexual orientation, gender
        perception or identity, national origin, age, marital status, protected
        veteran status, or disability status. EEO is the Law.
      </div>
    </div>
  );
};

export default Careers;
