import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
// import { signinSchema } from "../lib/validation/signinShema";
import toast from "react-hot-toast";
import { login } from "../slices/auth";
import { clearMessage } from "../slices/message";
import { getErrorsBackend } from "../lib/helper";
import FieldFormik from "../components/Fields/FieldFormik";
import Label from "../components/Fields/Label";
import BaseButton from "../components/Buttons/BaseButton";
import BaseAlert from "../components/Texts/BaseAlert";
import ReactGA from "react-ga";

const styles = {
  errorMsg: "text-red-500 text-sm",
};
const Signin = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearMessage());
  }, [dispatch]);

  useEffect(() => {
    ReactGA.pageview("/sign-in");
  }, []);

  function onLogIn(values) {
    const { loginId, password } = values;
    setLoading(true);

    dispatch(login({ loginId, password }))
      .unwrap()
      .then(() => {
        notify("Sign In Successful. Welcome!");
        const customRedirect = searchParams.get("redirect");
        if (customRedirect) {
          navigate(customRedirect);
        } else {
          navigate("/dashboard/profile");
        }
      })
      .catch(() => {
        setLoading(false);
      });
  }
  const notify = (message) => toast.success(message);
  if (isLoggedIn) {
    return <Navigate to="/dashboard/profile" />;
  }
  return (
    <div className="p-5 max-w-md mx-auto mt-10">
      <h1 className="text-3xl py-5 text-center font-roboto-slab">Sign In</h1>
      {message && message.message && (
        <BaseAlert type="danger" text={message.message} />
      )}
      <Formik
        initialValues={{
          loginId: "",
          password: "",
        }}
        //validate in front end
        //validationSchema={signinSchema}
        onSubmit={(values) => {
          onLogIn(values);
        }}
      >
        <Form>
          <div className="form-group">
            <Label label="Email / Username" htmlFor="Email" />
            <FieldFormik id="loginId" name="loginId" />
            <ErrorMessage
              component="a"
              className={styles.errorMsg}
              name="loginId"
            />
            {message &&
              message.error &&
              getErrorsBackend("email", message.error)}
          </div>
          <div className="form-group">
            <Label label="Password" htmlFor="Password" />
            <FieldFormik id="password" name="password" type="password" />
            <ErrorMessage
              component="a"
              className={styles.errorMsg}
              name="password"
            />
            {message &&
              message.error &&
              getErrorsBackend("password", message.error)}
          </div>
          <div className="mt-8 flex flex-col">
            <div className="flex">
              <BaseButton
                type="submit"
                disabled={loading}
                loading={loading ? 1 : 0}
                text="Submit"
              />
              <a href="/signup" className="mx-4 self-center">
                No account?{" "}
                <span className=" text-button font-bold ">Sign Up</span>
              </a>
            </div>
            <div className="my-5">
              <a href="/forgot-password" className="self-center">
                Don't remember your password?
              </a>
            </div>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default Signin;
