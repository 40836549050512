import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { Formik, Form, ErrorMessage } from "formik";
import Layout from "../components/Layout";
import FieldFormik from "../components/Fields/FieldFormik";
import Label from "../components/Fields/Label";
import BaseButton from "../components/Buttons/BaseButton";
import BaseAlert from "../components/Texts/BaseAlert";
import { useParams } from "react-router-dom";
import authService from "../services/auth.service";
import _ from "lodash";
import { resetPasswordSchema } from "../lib/validation/inputSchema";
import Loading from "../components/Animations/Loading";
const SignUpSetPassword = () => {
   const navigate = useNavigate();
   const [loading, setLoading] = useState(false);
   const [loadingUpdate, setLoadingUpdate] = useState(false);
   const [isTokenValid, setIsTokenValid] = useState(false);
   const [isDone, setIsDone] = useState(false);
   const { message } = useSelector((state) => state.message);

   const { token, hash } = useParams();

   useEffect(() => {
      checkTokenValid();
      return () => {
         setIsDone(false);
         setIsTokenValid(false);
      };
   }, [token, hash]);

   const initValues = {
      password: "",
      retypePassword: "",
   };

   const checkTokenValid = async () => {
      //set temp user info
      setLoading(true);
      try {
         const response = await authService.checkResetPasswordToken({
            hash,
            token,
         });
         if (response.status === 200) {
            setLoading(false);
            setIsTokenValid(true);
         } else {
            setLoading(false);
            setIsTokenValid(false);
         }
      } catch (error) {
         setLoading(false);
         setIsTokenValid(false);
      }
   };

   const onSetPassword = async (values) => {
      const { password } = values;
      try {
         const response = await authService.updatePassword({
            hash,
            token,
            password,
         });
         if (response.status === 200) {
            setIsDone(true);
            setLoadingUpdate(false);
         } else {
            setLoadingUpdate(false);
            setIsTokenValid(false);
         }
      } catch (error) {
         setLoadingUpdate(false);
         setIsTokenValid(false);
      }
   };

   if (_.isEmpty(token) || _.isEmpty(hash)) {
      return <Navigate to="/signin" />;
   }
   if (loading) {
      return <Loading size={6} />;
   }
   if (!isTokenValid) {
      return (
         <Layout>
            <div className="p-5 mt-10">
               <BaseAlert
                  type="danger"
                  text="The set password URL is invalid or expired! Please submit another request."
               />
            </div>
         </Layout>
      );
   }

   if (isTokenValid && isDone) {
      return (
         <Layout>
            <div className="p-5 max-w-md mx-auto mt-10">
               <BaseAlert
                  type="light"
                  text={
                     <div>
                        <div className="text-lg font-semibold text-green-600">
                           Your password has been set successfully!
                        </div>
                        <div>Please use your new password to sign in </div>
                     </div>
                  }
               />
               <div className="">
                  <button
                     type="button"
                     className="inline-block w-full px-6 py-2.5 bg-green-500 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
                     onClick={() => navigate("/signin")}
                  >
                     Sign In
                  </button>
               </div>
            </div>
         </Layout>
      );
   }

   return (
      <Layout>
         <div className="p-5 max-w-md mx-auto mt-10">
            <h1 className="text-3xl py-5">Set Password</h1>
            {message && message.message && (
               <div
                  className="bg-red-100 rounded-lg py-5 px-6 mb-4 text-base text-red-700 mb-3"
                  role="alert"
               >
                  {message.message}
               </div>
            )}
            <Formik
               initialValues={initValues}
               validationSchema={resetPasswordSchema}
               onSubmit={(values) => {
                  onSetPassword(values);
               }}
            >
               {({ resetForm }) => (
                  <Form>
                     <div className="form-group">
                        <Label label="New Password" htmlFor="Password" />
                        <FieldFormik
                           id="password"
                           name="password"
                           type="password"
                        />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="password"
                        />
                     </div>
                     <div className="form-group">
                        <Label
                           label="Retype Password"
                           htmlFor="retypePassword"
                        />
                        <FieldFormik
                           id="retypePassword"
                           name="retypePassword"
                           type="password"
                        />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="retypePassword"
                        />
                     </div>
                     <div className="mt-8 flex flex-wrap gap-x-3">
                        <BaseButton
                           type="submit"
                           disabled={loadingUpdate}
                           loading={loadingUpdate ? 1 : 0}
                           text="Submit"
                        />
                     </div>
                  </Form>
               )}
            </Formik>
         </div>
      </Layout>
   );
};

export default SignUpSetPassword;
