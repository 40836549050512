// add protected components here
import React from "react";
import { Navigate } from "react-router-dom";
import Loading from "../components/Animations/Loading";
import Layout from "../components/Layout";
import Signin from "../pages/Signin";
import Home from "../pages/Home";
import ContactUs from "../pages/ContactUs";
import NewChurchSubmission from "../pages/NewChurchSubmission";
import ManageChurchSubmission from "../pages/ManageChurchSubmission";
import EditChurchSubmission from "../pages/EditChurchSubmission";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import SearchAll from "../pages/SearchAll";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsOfService from "../pages/TermsOfService";
import Founder from "../pages/Founder";
import AboutUs from "../pages/AboutUs";
import ThankYou from "../pages/ThankYou";
import Careers from "../pages/Careers";
import PressKit from "../pages/PressKit";
import LearnMore from "../pages/LearnMore";
import SignupWithEmail from "../pages/SignupWithEmail";
import SignUpSetPassword from "../pages/SignUpSetPassword";

// private lazy loaded pages
const LazyUserProfilePage = React.lazy(() =>
  import("../pages/Dashboard/UserProfile.js")
);
const LazyUserManagementPage = React.lazy(() =>
  import("../pages/Dashboard/UserManagement")
);
const LazyChurchDetailPage = React.lazy(() =>
  import("../pages/ChurchDetail.js")
);
const LazyProfilePage = React.lazy(() =>
  import("../pages/Dashboard/Profile.js")
);
const LazyAddChurchPage = React.lazy(() =>
  import("../pages/Dashboard/AddChurch.js")
);
const LazyEditChurchPage = React.lazy(() =>
  import("../pages/Dashboard/EditChurch.js")
);

const LazyChurchManagementPage = React.lazy(() =>
  import("../pages/Dashboard/ChurchManagement")
);

const LazyTopSearchManagementPage = React.lazy(() =>
  import("../pages/Dashboard/TopSearchManagement")
);

const LazyPastorManagementPage = React.lazy(() =>
  import("../pages/Dashboard/PastorManagement")
);

const LazyChurchUserManagementPage = React.lazy(() =>
  import("../pages/Dashboard/ChurchUserManagement")
);

export const roles = {
  ADMIN: "ROLE_ADMIN",
  SUPERADMIN: "ROLE_SUPERADMIN",
};

export const routes = {
  publicRedirectRoute: "/signin", // redirect to `/signin` when authorized is trying to access public routes
  privateRedirectRoute: "/signin", // redirect to `/signin` when unauthorized user access a private route
  defaultFallback: (
    <Layout>
      <Loading size={6} />
    </Layout>
  ),
  // if the role of the user (`userRole` props) is not contained in the route `roles`
  InvalidUserRoleFallback: ({ currentUserRole, routeRequiredRoles }) => (
    <Navigate to="/dashboard/profile" />
  ),
  public: [],
  private: [
    {
      path: "/dashboard/profile",
      component: <LazyProfilePage />,
    },
    {
      path: "/dashboard/profile/account",
      component: <LazyUserProfilePage />,
    },
    {
      path: "/dashboard/add-church",
      component: <LazyAddChurchPage />,
      roles: [roles.SUPERADMIN],
    },
    {
      path: "/dashboard/edit-church/:churchId",
      component: <LazyEditChurchPage />,
      // roles: [roles.SUPERADMIN],  // TODO: Check on this
    },
    {
      path: "/dashboard/user-management",
      component: <LazyUserManagementPage />,
      roles: [roles.SUPERADMIN],
    },
    {
      path: "/dashboard/church-management",
      component: <LazyChurchManagementPage />,
      // roles: [roles.SUPERADMIN], // TODO: Check on this
    },
    {
      path: "/dashboard/top-search-management",
      component: <LazyTopSearchManagementPage />,
      roles: [roles.SUPERADMIN],
    },
    {
      path: "/dashboard/church/:churchId/user-management",
      component: <LazyChurchUserManagementPage />,
      roles: [roles.SUPERADMIN],
    },
    {
      path: "/dashboard/pastor-management",
      component: <LazyPastorManagementPage />,
      roles: [roles.SUPERADMIN],
    },
  ],
  common: [
    {
      path: "/",
      component: <Home />,
    },
    {
      path: "/contact",
      component: <ContactUs />,
    },
    {
      path: "/new-church-submission",
      component: <NewChurchSubmission />,
    },
    {
      path: "/manage-church-submission",
      component: <ManageChurchSubmission />,
    },
    {
      path: "/edit-church-submission",
      component: <EditChurchSubmission />,
    },
    {
      path: "/privacy",
      component: <PrivacyPolicy />,
    },
    {
      path: "/tos",
      component: <TermsOfService />,
    },
    {
      path: "/about-us",
      component: <AboutUs />,
    },
    {
      path: "/thank-you",
      component: <ThankYou />,
    },
    {
      path: "/meet-the-founder",
      component: <Founder />,
    },
    {
      path: "/careers",
      component: <Careers />,
    },
    {
      path: "/press-kit",
      component: <PressKit />,
    },
    {
      path: "/learn-more",
      component: <LearnMore />,
    },
    {
      path: "/forgot-password",
      component: <ForgotPassword />,
    },
    {
      path: "/reset-password/:hash/:token",
      component: <ResetPassword />,
    },
    {
      path: "/signup-set-password/:hash/:token",
      component: <SignUpSetPassword />,
    },
    {
      path: "/search/churches",
      component: <SearchAll />,
    },
    {
      path: "/church/:churchId",
      component: <LazyChurchDetailPage />,
    },
    {
      path: "/signup",
      component: <SignupWithEmail />,
    },
    {
      path: "/signin",
      component: (
        <Layout>
          <Signin />
        </Layout>
      ),
    },
    {
      path: "*",
      component: <p>page not found 404</p>,
    },
  ],
};
