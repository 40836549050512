import * as openpgp from "openpgp";
import authService from "./auth.service";
const encryptMessage = async (message) => {
   try {
      //get key here
      const res = await authService.getPublicKey();
      const publicKey = await openpgp.readKey({ armoredKey: res.data });

      const encrypted = await openpgp.encrypt({
         message: await openpgp.createMessage({ text: message }),
         encryptionKeys: publicKey,
      });
      return encrypted;
   } catch (err) {
      //console.log("Encrypt message is failed!", err);
   }
};

const EncryptionService = {
   encryptMessage,
};

export default EncryptionService;
