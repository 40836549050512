import React, { useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppRouter, Routes } from "auth-react-router";
import { Toaster } from "react-hot-toast";
import { logout } from "./slices/auth";
import eventBus from "./lib/EventBus";
import { routes } from "./routes/routes";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { Helmet } from "react-helmet";
import _ from "lodash";
import useIsSuperAdmin from "./hooks/useIsSuperAdmin";
import ReactGA from "react-ga";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

ReactGA.initialize("G-HY55N891Y3");

function App() {
  const [showSuperAdminBoard] = useIsSuperAdmin();
  const { user: currentUser } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const logOut = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);
  useEffect(() => {
    eventBus.on("logout", () => {
      logOut();
    });
    return () => {
      eventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  useEffect(() => {
    if (!_.isNull(currentUser)) {
      if (!currentUser.hasOwnProperty("id")) {
        eventBus.on("logout", () => {
          logOut();
        });
      }
    }
    return () => {
      eventBus.remove("logout");
    };
  }, [currentUser, logOut]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.pageview("/app");
  }, []);

  return (
    <Elements stripe={stripePromise}>
      <Fragment>
        <Helmet>
          {/* this is for tailwind tooltip component */}
          <script
            src="https://unpkg.com/@popperjs/core@2.9.1/dist/umd/popper.min.js"
            charset="utf-8"
          ></script>
          <title>StreamChurch</title>
        </Helmet>
        <Toaster position="top-center" reverseOrder={false} />
        <Nav
          currentUser={currentUser}
          showSuperAdminBoard={showSuperAdminBoard}
          logOut={logOut}
        />
        <AppRouter
          isAuth={currentUser}
          routes={routes}
          userRole={currentUser && currentUser.roles}
        >
          <Routes />
        </AppRouter>
        <Footer />
      </Fragment>
    </Elements>
  );
}

export default App;
