import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import discoverService from "../services/discover.service";

const Discover = () => {
  const [topSearches, setTopSearches] = useState();
  const [pastors, setPastors] = useState();
  const [recommendedChurches, setRecommendedChurches] = useState();
  useEffect(() => {
    fetchDiscover();

    return () => {};
  }, []);

  const fetchDiscover = async () => {
    const topSearches = await discoverService.getDiscoverTopSearches();
    setTopSearches(topSearches.data);

    const pastors = await discoverService.getDiscoverPastors();
    setPastors(pastors.data);

    const churches = await discoverService.getDiscoverChurches();
    setRecommendedChurches(churches.data);
  };

  const TopSearchesComponent = (data) => (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
      {data &&
        data.map((e, i) => (
          <Link
            to={`/search/churches?discover=true&value=${e.keyword}`}
            key={i}
          >
            <div className="text-gray-500 text-sm" key={i}>
              {e.keyword}
            </div>
          </Link>
        ))}
    </div>
  );
  const DiscoverComponent = (data) => (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-5">
      {data &&
        data.map((e, i) => (
          <Link to={`/search/churches?discover=true&value=${e.name}`} key={i}>
            <div className="text-gray-500 text-sm" key={i}>
              {e.name}
            </div>
          </Link>
        ))}
    </div>
  );

  return (
    <div className="mt-10 mx-auto max-w-7xl px-4">
      <h1 className="text-2xl md:text-3xl font-bold p-2 font-roboto-slab">
        Discover
      </h1>

      <ul
        className="nav nav-tabs flex flex-col md:flex-row flex-wrap list-none pl-0"
        id="tabs-tab"
        role="tablist"
      >
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-topsearches"
            className="
                  discover-nav-link
                  block
                  font-semibold
                  text-gray-600
                  text-md
                  leading-tight
                  capitalize
                  px-6
                  py-3
                  hover:bg-gray-100
                  focus:text-white
                  rounded-t-xl
                  active
    "
            id="tabs-topsearches-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-topsearches"
            role="tab"
            aria-controls="tabs-topsearches"
            aria-selected="false"
          >
            Top Searches
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-pastors"
            className="
                  discover-nav-link
                  block
                  font-semibold
                  text-gray-600
                  text-md
                  leading-tight
                  capitalize
                  px-6
                  py-3
                  hover:bg-gray-100
                  focus:text-white
                  md:rounded-t-xl
    "
            id="tabs-pastors-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-pastors"
            role="tab"
            aria-controls="tabs-pastors"
            aria-selected="false"
          >
            Pastors
          </a>
        </li>
        <li className="nav-item" role="presentation">
          <a
            href="#tabs-churches"
            className="
                  discover-nav-link
                  block
                  font-semibold
                  text-gray-600
                  text-md
                  leading-tight
                  capitalize
                  px-6
                  py-3
                  hover:bg-gray-100
                  focus:text-white
                  md:rounded-t-xl
    "
            id="tabs-churches-tab"
            data-bs-toggle="pill"
            data-bs-target="#tabs-churches"
            role="tab"
            aria-controls="tabs-churches"
            aria-selected="false"
          >
            Recommended Churches
          </a>
        </li>
      </ul>
      <div className="tab-content border-b-2 py-4 mx-2" id="tabs-tabContent">
        <div
          className="tab-pane fade show active"
          id="tabs-topsearches"
          role="tabpanel"
          aria-labelledby="tabs-topsearches-tab"
        >
          {TopSearchesComponent(topSearches)}
        </div>
        <div
          className="tab-pane fade"
          id="tabs-pastors"
          role="tabpanel"
          aria-labelledby="tabs-pastors-tab"
        >
          {DiscoverComponent(pastors)}
        </div>
        <div
          className="tab-pane fade"
          id="tabs-churches"
          role="tabpanel"
          aria-labelledby="tabs-churches-tab"
        >
          {DiscoverComponent(recommendedChurches)}
        </div>
      </div>
    </div>
  );
};

export default Discover;
