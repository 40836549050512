import React from "react";
import Spinner from "./Spinner";

const Loading = ({ size }) => {
   return (
      <div className="p-20 text-center">
         <Spinner size={size} />
      </div>
   );
};

export default Loading;
