import * as Yup from "yup";
export const editChurchSchema = Yup.object().shape({
   name: Yup.string().required("Required"),
   // address: Yup.string().required("Required"),
   // city: Yup.string().required("Required"),
   // state: Yup.string().required("Required"),
   // zip: Yup.string()
      // .matches(/^[0-9]{5}$/, "Must be 5 digits")
      // .required("Required"),
   // generalDenom: Yup.string().required("Required"),
   // website: Yup.string().required("Required"),
   // description: Yup.string().required("Required"),
});
export const addChurchSchema = Yup.object().shape({
   channelID: Yup.string().required("Required"),
   name: Yup.string().required("Required"),
   // address: Yup.string().required("Required"),
   // city: Yup.string().required("Required"),
   // state: Yup.string().required("Required"),
   // zip: Yup.string().required("Required"),
   // denom: Yup.string().required("Required"),
   // specdenom: Yup.string().required("Required"),
   // networkid: Yup.string().required("Required"),
   // description: Yup.string().required("Required"),
   // website: Yup.string().required("Required"), // Has some UI issues
});

export const addUserSchema = Yup.object().shape({
   email: Yup.string().email().required("Required"),
   role: Yup.string().required("Required"),
});

export const editUserSchema = Yup.object().shape({
   role: Yup.string().required("Required"),
});
export const forgotPasswordSchema = Yup.object().shape({
   email: Yup.string().email().required("Required"),
});

export const signUpSchema = Yup.object().shape({
   email: Yup.string().email().required("Required"),
   name: Yup.string().required("Required"),
   username: Yup.string().required("Required"),
});

export const fileSchema = Yup.object().shape({
   signator_name: Yup.string().min(3).max(20).required(),
   digital_signature: Yup.mixed().required(),
});

export const contactSchema = Yup.object().shape({
   email: Yup.string().email().required("Required"),
   name: Yup.string().required("Required"),
   subject: Yup.string().required("Required"),
   message: Yup.string().required("Required"),
});

export const resetPasswordSchema = Yup.object().shape({
   password: Yup.string()
      .required("Required")
      .min(3, "password must be at least 3 characters!"),
   retypePassword: Yup.string().oneOf(
      [Yup.ref("password")],
      "Your passwords do not match."
   ),
});

export const newChurchSubmissionFormSchema = Yup.object().shape({
   name: Yup.string().required("Required"),
   address: Yup.string().required("Required"),
   zipcode: Yup.string().required("Required"),
   website: Yup.string().url().required("Required"),
   youtube: Yup.string().url().required("Required"),
   userEmail: Yup.string().email().required("Required"),
});

export const manageChurchSubmissionFormSchema = Yup.object().shape({
   churchIds: Yup.string().required("Required"),
   userName: Yup.string().required("Required"),
   userEmail: Yup.string().email().required("Required"),
});

export const editChurchSubmissionFormSchema = Yup.object().shape({
   churchId: Yup.string().required("Required"),
   userName: Yup.string().required("Required"),
   userEmail: Yup.string().email().required("Required"),
   updateDetails: Yup.string().required("Required"),
});
