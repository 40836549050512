import api, { API_URL } from "./api";
import TokenService from "./token.service";

const getChurchesBySize = async (page, size, filter, isManagementRequest = false) => {
   const response = await api.get(`/churches?page=${page}&size=${size}&filter=${filter}&management=${isManagementRequest}`);
   return response.data;
};

const getRecommendedChurchesBySize = async (page, size) => {
   const response = await api.get(
      `/churches/recommended?page=${page}&size=${size}`
   );
   return response.data;
};

const getChurchById = async (churchId) => {
   const response = await api.get(`/church/${churchId}`);
   return response.data;
};

const getChurchByIdForEdit = async (churchId) => {
   const response = await api.post(`/church/${churchId}/edit`);
   return response.data;

   // const headers = {
   //    "x-access-token": TokenService.getLocalAccessToken(),
   // };
   // const response = await fetch(`/api/church/${churchId}/edit`, {
   //    method: "POST",
   //    headers,
   // });
   // return response.data;
};

const getChurchUsersByChurchId= async (churchId) => {
   const response = await api.get(`/auth/admin/church/${churchId}/users`);
   return response.data;
};

const assignUserToChurch = async (churchId, userId) => {
   const response = await api.post(`/auth/admin/church/${churchId}/users/${userId}`);
   return response;
};

const unAssignUserToChurch = async (churchId, userId) => {
   const response = await api.delete(`/auth/admin/church/${churchId}/users/${userId}`);
   return response;
};

const getChurchWFavoriteById = async (churchId, userId, currentVideoId) => {
   const response = await api.get(
      `/auth/favorite/church?churchId=${churchId}&userId=${userId}&currentVideoId=${currentVideoId}`
   );
   return response.data;
};
const getChurchesBySearch = async (key, value, page, size) => {
   const response = await api.get(
      `/search/churches/filter?key=${key}&value=${value}&page=${page}&size=${size}`
   );
   return response.data;
};

const getChurchesBySearchVector = async (page, size, value, filter) => {
   const response = await api.get(
      `/search/churches?filter=${filter}&value=${value}&page=${page}&size=${size}`
   );
   return response.data;
};

const getNearByChurches = async (lat, long) => {
   const response = await api.get(
      `/search/near-by?lat=${lat}&lang=${long}`
   );
   return response.data;
};

const getStates = async () => {
   const response = await api.get(`/states`);
   return response.data;
};

const getDenominations = async () => {
   const response = await api.get(`/denominations`);
   return response.data;
};

const getSpecificDenominations = async () => {
   const response = await api.get(`/specific-denominations`);
   return response.data;
};

const getGeneralDenominations = async () => {
   const response = await api.get(`/general-denominations`);
   return response.data;
};

const getNetworks = async () => {
   const response = await api.get(`/networks`);
   return response.data;
};

// const updateChurchByAdmin = async (church) => {
//    const response = await api.put("/auth/admin/church", church);
//    return response.data;
// };

const updateChurchByAdminWFormData = async (file) => {
   const headers = {
      //"Content-Type": "multipart/form-data",
      "x-access-token": TokenService.getLocalAccessToken(),
   };
   const response = await fetch(`${API_URL}/auth/admin/church`, {
      method: "PUT",
      headers,
      body: file,
   });
   // const response = await uploadService.uploadAPI.put(
   //    "/auth/admin/church",
   //    file
   // );
   return response;
};

const addChurchByAdmin = async (church) => {
   const response = await api.post("/auth/admin/church", church);
   return response.data;
};

const removeChurchByAdmin = async (churchId) => {
   const response = await api.delete(`/auth/admin/church/${churchId}`);
   return response;
};

const addChurchWithFormData = async (file) => {
   const headers = {
      //"Content-Type": "multipart/form-data",
      "x-access-token": TokenService.getLocalAccessToken(),
   };
   const response = await api.post(`/auth/admin/church`, file);
   // const response = await uploadService.uploadAPI.post(
   //    `/auth/admin/church`,
   //    file
   // );
   return response;
};

const fetchAdvanceSearch = async (page,
   size,
   value,
   filter,
   advanceFilter,
   type) => {
   const response = await api.post(`/advance-search`, { page,
      size,
      value,
      filter,
      advanceFilter,
      type });
   return response.data;
};

const addNewNetwork = async (networkName) => {
   const response = await api.post("/network",  { name: networkName});
   return response.data;
};

const addNewLanguage = async (language) => {
   const response = await api.post("/language",  { name: language});
   return response.data;
};

const getAllLanguages = async () => {
   const response = await api.get("/languages");
   return response.data;
};

const churchService = {
   getChurchesBySize,
   getRecommendedChurchesBySize,
   getChurchById,
   getChurchesBySearch,
   getChurchWFavoriteById,
   getChurchesBySearchVector,
   addChurchByAdmin,
   removeChurchByAdmin,
   addChurchWithFormData,
   updateChurchByAdminWFormData,
   getStates,
   getDenominations,
   getSpecificDenominations,
   getNetworks,
   getGeneralDenominations,
   fetchAdvanceSearch,
   getChurchUsersByChurchId,
   assignUserToChurch,
   unAssignUserToChurch,
   addNewNetwork,
   addNewLanguage,
   getAllLanguages,
   getNearByChurches,
   getChurchByIdForEdit
};

export default churchService;
