import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Layout from "../components/Layout";
import { Formik, Form, ErrorMessage } from "formik";
import FieldFormik from "../components/Fields/FieldFormik";
import Label from "../components/Fields/Label";
import BaseButton from "../components/Buttons/BaseButton";
import BaseAlert from "../components/Texts/BaseAlert";
import { signUpSchema } from "../lib/validation/inputSchema";
import authService from "../services/auth.service";
import toast from "react-hot-toast";
import { Navigate } from "react-router-dom";
import ReactGA from "react-ga";
import useIsMobile from "../hooks/useIsMobile";

const SignupWithEmail = () => {
  const { message } = useSelector((state) => state.message);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [isDone, setIsDone] = useState(false);
  const [resetMessage, setResetMessage] = useState();
  const [emailForReset, setEmailForReset] = useState();
  const [name, setName] = useState();

  const isMobile = useIsMobile();

  useEffect(() => {
    return () => setIsDone(false);
  }, []);

  useEffect(() => {
    ReactGA.pageview("/sign-up-with-email");
  }, []);

  const onSignUp = async (values) => {
    const { email, name, zipcode, username } = values;
    setEmailForReset(email);
    setName(name);
    setLoading(true);
    try {
      const response = await authService.signUpWithEmail({
        email: email.toLowerCase(),
        name,
        zipcode,
        username,
      });
      if (response.status === 200) {
        setIsDone(true);
        setLoading(false);
        //setResetMessage(response.data.message)
        toast.success(
          response?.data?.message ||
            "Your request was received successfully, Please check your inbox for the next steps!"
        );
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setResetMessage(
        error.response?.data?.message ||
          "Failed to send out a request. If the problem continues, please contact the administrator."
      );
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/dashboard/profile" />;
  }

  if (isDone) {
    return (
      <Layout>
        <div className="p-5 max-w-md mx-auto mt-10 text-center">
          <h1 className="text-3xl py-5 text-center">Check your inbox!</h1>
          <p className="font-small leading-tight text-4m mt-0 mb-2 text-gray-600">
            Hi {name}, We have sent the instructions to,
          </p>
          <h2 className="font-medium leading-tight text-4xl mt-0 mb-2 text-gray-600 lowercase">
            {emailForReset}
          </h2>
        </div>
      </Layout>
    );
  }

  return (
    <div className="md:mt-10 mx-auto md:px-36 overflow-x-auto hide-scroll-bar bg-church bg-cover bg-no-repeat -mb-20">
      <div className="flex flex-col overflow-x-auto hide-scroll-bar md:grid md:grid-cols-2 py-2">
        {!isMobile ? (
          <div className="flex flex-col border-box bg-button text-white md:mr-0">
            <Layout>
              <div className="p-5 max-w-md mx-auto mt-10">
                <h1 className="text-3xl py-4 text-center font-roboto-slab">
                  By creating an account,
                  <br /> you can...
                </h1>
                <p className="my-2">
                  <ul>
                    <li>• Follow your favorite churches.</li>
                    <li>• Save Sermons that you enjoy.</li>
                    <li>
                      • Take notes during a sermon and save them to your
                      profile.
                    </li>
                    <li>
                      • Creating an account is FREE, it just unlocks more
                      features!
                    </li>
                  </ul>
                </p>
              </div>
            </Layout>
          </div>
        ) : (
          ""
        )}
        <div
          className="flex flex-col border-box bg-white"
          style={isMobile ? { marginRight: "10px" } : { marginRight: "100px" }}
        >
          <Layout>
            <div className="p-5 max-w-md mx-auto mt-10">
              <h1 className="text-3xl py-4 font-roboto-slab">Sign Up:</h1>
              <p className="my-2">
                Enter your details & email address and we'll email you
                instructions on how to set your password
              </p>
              {resetMessage && <BaseAlert type="danger" text={resetMessage} />}
              <Formik
                initialValues={{
                  email: "",
                  name: "",
                  zipcode: "",
                  username: "",
                }}
                //validate in front end
                validationSchema={signUpSchema}
                onSubmit={(values) => {
                  onSignUp(values);
                }}
              >
                <Form>
                  <div className="form-group">
                    <FieldFormik id="name" name="name" placeholder="Name *" />
                    <ErrorMessage
                      component="a"
                      className="text-red-500 text-sm"
                      name="name"
                    />
                  </div>
                  <div className="form-group">
                    <FieldFormik
                      id="email"
                      name="email"
                      placeholder="Email *"
                    />
                    <ErrorMessage
                      component="a"
                      className="text-red-500 text-sm"
                      name="email"
                    />
                  </div>
                  <div className="form-group">
                    <FieldFormik
                      id="username"
                      name="username"
                      placeholder="Username *"
                    />
                    <ErrorMessage
                      component="a"
                      className="text-red-500 text-sm"
                      name="username"
                    />
                  </div>
                  <div className="form-group">
                    <FieldFormik
                      id="zipcode"
                      name="zipcode"
                      placeholder="Zipcode"
                    />
                    <ErrorMessage
                      component="a"
                      className="text-red-500 text-sm"
                      name="zipcode"
                    />
                  </div>
                  <div className="mt-8 flex">
                    <BaseButton
                      type="submit"
                      disabled={loading}
                      loading={loading ? 1 : 0}
                      text="Submit"
                    />
                  </div>
                </Form>
              </Formik>
            </div>
          </Layout>
        </div>
      </div>
    </div>
  );
};

export default SignupWithEmail;
