import React, { useState, useEffect } from "react";
import { Formik, Form, ErrorMessage } from "formik";
import toast from "react-hot-toast";
import FieldFormik from "../components/Fields/FieldFormik";
import BaseButton from "../components/Buttons/BaseButton";
import Label from "../components/Fields/Label";
import Layout from "../components/Layout";
import emailService from "../services/email.service";
import { manageChurchSubmissionFormSchema } from "../lib/validation/inputSchema";
import ReactRecaptcha3 from 'react-google-recaptcha3';

const SITE_RECAPTCHA3_SITEKEY = "6LfDwigiAAAAAJbtpSRjMWz9VxyPJ-0XH9ChYb2m";

const ManageChurchSubmission = () => {

   const [loading, setLoading] = useState(false);
   const initValues = {
      churchIds: "",
      userName: "",
      userEmail: "",
      other: "",
   };

   const focusToTopSearchElements = async () => {
      const delay = ms => new Promise(res => setTimeout(res, ms));
      await delay(100);
      const searchKeyElements = document.querySelector('.form-submission-title');
      if (searchKeyElements) {
        searchKeyElements.scrollIntoView({ behavior: 'smooth', block: 'center' });
        searchKeyElements.previousElementSibling?.focus({ preventScroll: true });
      }
   }

   useEffect(() => {
      ReactRecaptcha3.init(SITE_RECAPTCHA3_SITEKEY).then(status => {
         console.log("Recaptcha initialized: " + status);
       })
      focusToTopSearchElements();
    }, [])

   const onSend = async (values) => {
      setLoading(true);
      try {

         const token = await ReactRecaptcha3.getToken();
          
         const response = await emailService.sendPublicForumSubmission("MANAGE_CHURCH_SUBMISSION", values, token);
         if (response.status === 200) {
            toast.success(response.data.message);
            setLoading(false);
         }
         setLoading(false);
      } catch (error) {
         console.log(error)
         toast.error(error.response.data.message);
         setLoading(false);
      }
   };

   return (
      <Layout>
         <div className="global-form-layout">
            <h1 className="text-3xl py-5 text-center form-submission-title">Help us to manage church contents!</h1>
            <h4 className="py-5 text-center"> If you would like to manage one or more church contens from this site, Please submit your request here. Our team will review the details, and reach out to you for questions.</h4>
            <hr /> 
            <Formik
               initialValues={initValues}
               validationSchema={manageChurchSubmissionFormSchema}
               onSubmit={(values) => {
                  onSend(values);
               }}
            >
               {({ resetForm }) => (
                  <Form>
                     <div className="form-group">
                        <Label label="Church Id(s) / Church name(s)" />
                        <FieldFormik id="churchIds" name="churchIds" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="churchIds"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Your name" />
                        <FieldFormik id="userName" name="userName" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="userName"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Your email" />
                        <FieldFormik id="userEmail" name="userEmail" />
                        <ErrorMessage
                           component="a"
                           className="text-red-500 text-sm"
                           name="userEmail"
                        />
                     </div>
                     <div className="form-group">
                        <Label label="Other details" />
                        <FieldFormik
                           as="textarea"
                           rows="5"
                           id="other"
                           name="other"
                        />
                     </div>

                     <div className="mt-8 flex flex-wrap gap-x-3">
                        <BaseButton
                           type="submit"
                           disabled={loading}
                           loading={loading ? 1 : 0}
                           text="Submit Your Request"
                        />
                     </div>
                  </Form>
               )}
            </Formik>
         </div>
      </Layout>
   );
};

export default ManageChurchSubmission;
